const DetermineTrendingColor = (score) => {
  if (score === null || score < -100000000) {
    return 'transparent';
  }
  if (score <= -10) {
    return '#66CC44';
  }
  if (score <= -8 && score > -10) {
    return '#86BE74';
  }
  if (score <= -6 && score > -8) {
    return '#8CBC7D';
  }
  if (score <= -4 && score > -6) {
    return '#93B987';
  }
  if (score <= -2.25 && score > -4) {
    return '#99B691';
  }
  if (score <= -1 && score > -2.25) {
    return '#A0B49A';
  }
  if (score <= -0.25 && score > -1) {
    return '#A6B1A4';
  }
  if (score <= 0.25 && score > -0.25) {
    return '#ADAFAE';
  }
  if (score <= 1 && score > 0.25) {
    return '#B49F9E';
  }
  if (score <= 2.25 && score > 1) {
    return '#BB8F8E';
  }
  if (score <= 4 && score > 2.25) {
    return '#C37F7E';
  }
  if (score <= 6 && score > 4) {
    return '#CA6F6E';
  }
  if (score <= 8 && score > 6) {
    return '#D25F5E';
  }
  if (score <= 10 && score > 8) {
    return '#D94F4F';
  }
  if (score >= 10) {
    return '#FF0000';
  }

  return;
};

export default DetermineTrendingColor;
