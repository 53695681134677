import React from 'react';
import styled from 'styled-components';

import Sidebar from '../Sidebar/index';
import DataPanel from '../DataPanel';
import MapboxGl from '../MapboxGl';
import MapLegend from '../MapLegend';
import power from '../../assets/poweredby.svg';
import citiiq from '../../assets/logo.svg';
import expand from '../../assets/expand2.svg';

import timestamp from '../../data/Timestamp.json';
import {
  bottomPanelHeight,
  footerHeight,
  footerHeightShrinked,
  bottomPanelHeightShrinked,
  windowHeightBreakpoint
} from '../../helpers/StyleHelper';

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: #1c1f26;
`;

const CitiIQPanel = styled.div`
  width: 100%;
  height: ${footerHeight};
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  background-color: #2a2a31;
  @media screen and (max-height: ${windowHeightBreakpoint}) {
    height: ${footerHeightShrinked};
  }
`;

const MapContainer = styled.div`
  width: 100%;
  top: 0;
  position: absolute;
  width: 57.5vw;
  margin-left: 23.5vw;
  flex-direction: row;
  height: calc(100vh - ${footerHeight} - ${bottomPanelHeight} - 10px);
  @media screen and (max-height: ${windowHeightBreakpoint}) {
    height: calc(
      100vh - ${bottomPanelHeightShrinked} - ${footerHeightShrinked} - 10px
    );
  }
`;

const DataSourcesToggle = styled.div`
  width: auto;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
`;
const LogoWrapper = styled.a`
  width: auto;
  height: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  cursor: pointer;
`;

const PoweredBy = styled.div`
  background-image: url(${power});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 55px;
  height: 100%;
`;
const Logo = styled.div`
  width: 55px;
  height: 100%;
  background-image: url(${citiiq});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 9px;
`;
const Expand = styled.div`
  width: 20px;
  height: 50%;
  background-image: url(${expand});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 8px;
`;

const SourcesPanel = styled.div`
  height: 427px;
  width: 504px;
  position: absolute;
  z-index: 100;
  left: 1vh;
  bottom: 6vh;
  background-color: #1c2026;
  border-radius: 8px;
  overflow: scroll;
  boz-sizing: border-box;
  padding 15px;
`;

const Text = styled.p`
  color: white;
  font-size: 12px;
`;

const LeftSideContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LastUpdatedWrapper = styled.div`
  display: flex;
  color: white;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;
`;

const LastUpdatedText = styled.p`
  color: white;
  font-size: 12px;
  margin-bottom: 0;
`;

const DesktopContainer = () => {
  const [showSources, setShowSources] = React.useState(false);
  const [popupInfo, setPopupInfo] = React.useState(false);

  return (
    <PageContainer>
      <Sidebar />
      <DataPanel />
      <MapContainer>
        <MapboxGl popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
        <MapLegend />
      </MapContainer>
      <CitiIQPanel>
        {showSources ? (
          <SourcesPanel>
            <Text>
              CitiIQ relies on a compilation of the most current data sources
              available to deliver accurate city information to users. Various
              indicators are based on a synthesis of other variables. The
              collected, cleaned and vetted data is then used on an exclusive
              algorithm that normalizes and weights the data to allow for
              comparisons from city to city and over time. Data was accessed
              between March-May 2020 from the sources below. Dates on the raw
              data vary by organization and type.
            </Text>
            <Text>
              Data sources include: Oxford Economics, Knoema, Numbeo, World
              Bank, Organization for Economic Cooperation and Development,
              UN-Development Programme, UN-Habitat, UNESCO, World Health
              Organization, The Economist Intelligence Unit, Johns Hopkins
              University (Center for Systems Science and Engineering),
              worldometers.info, DataReportal, Our World in Data, UN ITU,
              TrekMedics International, International Labour Office, Trading
              Economics, GSMA (Global Systems for Mobile Communications),
              Reporters without borders (RSF) and The Global Economy
            </Text>
            <Text>
              <i>
                The designations employed and the presentation of material on
                this map do not imply the expression of any opinion whatsoever
                on the part of the Secretariat of the United Nations concerning
                the legal status of any country, territory, city or area or of
                its authorities, or concerning the delimitation of its frontiers
                or boundaries.
              </i>
            </Text>
          </SourcesPanel>
        ) : null}
        <LeftSideContent>
          <DataSourcesToggle onClick={() => setShowSources(!showSources)}>
            Data Sources
            <Expand />
          </DataSourcesToggle>
          <LastUpdatedWrapper>
            <LastUpdatedText>
              Last updated {timestamp[0].creationTime}
            </LastUpdatedText>
          </LastUpdatedWrapper>
        </LeftSideContent>
        <LogoWrapper href="https://citiiq.com" target="_blank">
          <PoweredBy />
          <Logo />
        </LogoWrapper>
      </CitiIQPanel>
    </PageContainer>
  );
};
export default DesktopContainer;
