import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import moment from 'moment';

import virus from '../images/virus.svg';
import flower from '../images/flower.svg';

import { windowHeightBreakpoint } from '../../../../helpers/StyleHelper';
import { CityContext } from '../../../../context/CityProvider';
import { CityScoresContext } from '../../../../context/CityScoresProvider';
import { PanelContext } from '../../../../context/PanelProvider';
import Toggle from '../GraphToggle';
import LoadingReticule from '../../../LoadingReticule';

const parseColorPoints = (data, color) => {
  const array = [];
  data.forEach(() => {
    array.push(color);
  });
  return array;
};

const formatDate = (dateString) => {
  return moment(dateString.split('.').join('')).format('YY MMM DD');
};

const sortAsc = function (a, b) {
  return parseInt(a.split('.').join('')) - parseInt(b.split('.').join(''));
};

const parseDataPoints = (data, dates, key) => {
  return dates.sort(sortAsc).map((date) => {
    if (!data[date]) return null;

    if (data[date][key] === '') {
      return null;
    }

    return data[date][key];
  });
};

const parseDateLabels = (data, format) => {
  return Object.keys(data)
    .sort(sortAsc)
    .map((date) => {
      return format ? formatDate(date) : date;
    });
};

// this merges multiple arrays into one & remove duplicates
function mergeArrays(...arrays) {
  let jointArray = [];

  arrays.forEach((array) => {
    jointArray = [...jointArray, ...array];
  });

  return [...new Set([...jointArray])];
}

const purifyData = (data, dates, overrideLabel) => {
  const FilteredCollection = {};
  if (overrideLabel !== 'default') {
    const removeExcessData = dates.filter(
      (date) => data[date]?.Type === overrideLabel
    );

    removeExcessData.forEach((filterDateList) => {
      FilteredCollection[filterDateList] = data[filterDateList];
    });
  } else {
    const removeExcessData = dates.filter(
      (date) => data[date]?.Type === undefined
    );

    removeExcessData.forEach((filterDateList) => {
      FilteredCollection[filterDateList] = data[filterDateList];
    });
  }
  return FilteredCollection;
};

const CumulativeGraphs = ({ cardStyle, handleToggle, toggle }) => {
  // grab historical data from context
  const { cityState } = React.useContext(CityContext);
  const { panelState } = React.useContext(PanelContext);
  const { cityScoresState } = React.useContext(CityScoresContext);
  const [loading, setLoading] = React.useState(true);

  const [LLdata, setLLData] = React.useState({});
  const [CCdata, setCCData] = React.useState({});

  React.useEffect(() => {
    if (
      cityScoresState.HistoricalData.countryData &&
      cityScoresState.HistoricalData.cityData &&
      cityState?.City?.properties?.city &&
      cityState?.City?.properties?.country
    ) {
      const { city, country, cityId } = cityState.City.properties;
      const countryJSON =
        cityScoresState.HistoricalData.countryData[country] || {};
      const cityJSON =
        cityScoresState.HistoricalData.cityData[String(cityId) + '.0'] || {};

      const lastOverrideLabel = Object.keys(cityJSON).sort((a, b) =>
        a > b ? 1 : -1
      )[Object.keys(cityJSON).length - 1];

      let overrideLabel = 'default';

      if (cityJSON[lastOverrideLabel]?.Type) {
        cityJSON[lastOverrideLabel].Type
          ? (overrideLabel = cityJSON[lastOverrideLabel].Type)
          : (overrideLabel = 'default');
      }

      const dateLabels = mergeArrays(
        parseDateLabels(countryJSON, true),
        parseDateLabels(cityJSON, true)
      );
      const dateKeys = mergeArrays(
        parseDateLabels(countryJSON),
        parseDateLabels(cityJSON)
      );

      const countryLivesLostDataPoints = parseDataPoints(
        countryJSON,
        dateKeys,
        'Deaths1000'
      );
      const countryCasesDataPoints = parseDataPoints(
        countryJSON,
        dateKeys,
        'Cases1000'
      );

      let cityLLDataPoints = parseDataPoints(cityJSON, dateKeys, 'Deaths1000');
      let cityCCDataPoints = parseDataPoints(cityJSON, dateKeys, 'Cases1000');

      const cleanedRecords3 = purifyData(cityJSON, dateKeys, overrideLabel);
      const cleanedRecords4 = purifyData(cityJSON, dateKeys, overrideLabel);
      cityLLDataPoints = parseDataPoints(
        cleanedRecords3,
        dateKeys,
        'Deaths1000'
      );
      cityCCDataPoints = parseDataPoints(
        cleanedRecords4,
        dateKeys,
        'Cases1000'
      );

      const arrayOfColors1 = parseColorPoints(
        countryLivesLostDataPoints,
        '#5CC19C'
      );
      const arrayOfColors2 = parseColorPoints(cityLLDataPoints, '#D658D9');

      const LivesLostData = {
        datasets: [
          {
            borderColor: '#5CC19C',
            data: countryLivesLostDataPoints,
            pointBackgroundColor: arrayOfColors1,
            label: country
          },
          {
            borderColor: '#D658D9',
            data: cityLLDataPoints,
            pointBackgroundColor: arrayOfColors2,
            label: city
          }
        ],
        labels: dateLabels
      };
      setLLData(LivesLostData);
      const ConfirmedCasesData = {
        datasets: [
          {
            borderColor: '#5CC19C',
            data: countryCasesDataPoints,
            pointBackgroundColor: arrayOfColors1,
            label: country
          },
          {
            borderColor: '#D658D9',
            data: cityCCDataPoints,
            pointBackgroundColor: arrayOfColors2,
            label: city
          }
        ],
        labels: dateLabels
      };
      setCCData(ConfirmedCasesData);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [cityState?.City?.properties?.city]);

  const options = {
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        fontColor: 'white'
      }
    },
    steppedLine: true,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          return `${tooltipItem.label}: ${parseFloat(tooltipItem.value).toFixed(
            3
          )}`;
        }
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Total Cases Per 100K',
            fontColor: 'white'
          },
          ticks: {
            stepSize: 0.5,
            autoSkip: true,
            maxTicksLimit: 6,
            fontColor: 'white'
          }
        }
      ],
      xAxes: [
        {
          showFirstLabel: true,
          showLastLabel: true,
          ticks: {
            stepSize: 1,
            autoSkip: true,
            maxRotation: 30,
            minRotation: 30,
            fontColor: 'white'
          }
        }
      ]
    }
  };

  const Container = styled.div`
    height: ${panelState.Panel === false ? '100%' : '240px'};
    width: 100%;
    background-color: #31353d;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      height: ${panelState.Panel === false ? '100%' : '165px'};
    }
  `;
  const CardHeader = styled.div`
    height: 34.5px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: white;
    padding-left: 10px;
    box-sizing: border-box;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      height: unset;
      font-size: 14px;
    }
    position: relative;
  `;

  const Icon = styled.div`
    width: 26px;
    height: 26px;
    background-image: url(${(props) => props.image});
    margin-top: 5px;
    background-size: 100% 100%;
    margin-right: 10px;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      width: 18px;
      height: 18px;
    }
  `;

  const Title = styled.div`
    margin-top: 5px;
    font-size: 18px;
    color: white;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      font-size: 14px;
    }
  `;
  const GraphWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 80%;
  `;

  return (
    <Container>
      {loading ? (
        <LoadingReticule />
      ) : (
        <>
          <CardHeader>
            <Icon image={cardStyle === 'livesLost' ? flower : virus} />
            <Title> {cardStyle === 'livesLost' ? 'Lives Lost' : 'Cases'}</Title>
            <Toggle handleToggle={handleToggle} toggle={toggle} />
          </CardHeader>
          <div style={{ height: '10px' }} />
          <GraphWrapper>
            <Line
              data={cardStyle === 'livesLost' ? LLdata : CCdata}
              height={null}
              width={null}
              options={options}
            />
          </GraphWrapper>
        </>
      )}
    </Container>
  );
};
export default CumulativeGraphs;
