import React from 'react';

const initialPanelState = {
  Panel: true,
};
const PanelReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        Panel: action.data,
      };

    default:
      throw new Error('Modal reducer case not found');
  }
};

export const PanelContext = React.createContext();
export const PanelProvider = ({ children }) => {
  const [panelState, dispatchPanelState] = React.useReducer(
    PanelReducer,
    initialPanelState,
  );

  return (
    <PanelContext.Provider value={{ panelState, dispatchPanelState }}>
      {children}
    </PanelContext.Provider>
  );
};
