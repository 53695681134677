import React from 'react';
import styled from 'styled-components';

import Panel from '../../Panel';
// import CityStats from "./PanelTypes/CityStats";
import Indicators from '../PanelTypes/Indicators';
import Impact from '../PanelTypes/Impact';
import ControllableIFrame from '../PanelTypes/ControllableIFrame';
import iframeLink from '../../../data/IframeContent.json';
import UNlogo from '../PanelTypes/images/desktopLogo@4x.png';

import {
  footerHeight,
  footerHeightShrinked,
  windowHeightBreakpoint,
  StyledScrollbarCSS
} from '../../../helpers/StyleHelper';

const UNlogoImageW = 936;
const UNlogoImageH = 144;

const LogoContainer = styled.div`
  flex-shrink: 0;
  flex: 0.25;
  display: flex;
  flex-direction: column;
  padding: 10px 8px;
  box-sizing: border-box;
`;

const LogoAnchor = styled.a`
  display: block;
  width: 100%;
  max-width: ${UNlogoImageW / 4}px;
  margin-left: auto;
  margin-bottom: 10px;
`;

const Logo = styled.div`
  width: 100%;
  height: 0;
  padding-top: ${(UNlogoImageH / UNlogoImageW) * 100}%;
  background-image: url(${UNlogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 12px;
  color: white;
  width: 100%;
  max-width: 234px;
  margin-left: auto;
  display: flex;
  justify-content: center;
`;

const IndividualVerticalCardWrapper = styled.div`
  height: auto;
  margin-left: -5px;
  margin-right: -5px;
  padding: 5px;
  background: rgb(28, 31, 38);
  border-top-left-radius: 5px;
  & + div:not(:last-child) {
    margin-top: -5px;
    border-top-left-radius: 0;
  }
  &:not(:last-child) {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    & > div {
      flex: 1;
      border-radius: 5px;
      background-color: #31353d;
      ${StyledScrollbarCSS}
      & > .card {
        height: auto;
      }
    }
  }
`;

const CornerCardWrapper = styled.div`
  height: auto;
  max-height: 100vh;
  background-color: #1c1f26;
  box-sizing: border-box;
  padding-bottom: 5px;
  height: 245px;
  padding: 0 5px;
  margin: 0 -5px;
  @media screen and (max-height: ${windowHeightBreakpoint}) {
    height: calc(165px + 5px);
  }
`;

const CustomVerticalSetter = styled.div`
  position: absolute;
  width: 19vw;
  right: 0;
  height: calc(100vh - ${footerHeight});
  bottom: ${footerHeight};
  padding: 0 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: flex-start;
  background: #1a1a1b;
  @media (max-height: ${windowHeightBreakpoint}) {
    height: calc(100vh - ${footerHeightShrinked});
    bottom: ${footerHeightShrinked};
  }
`;

// const ImpactCardWrapper = styled.div`
//   height: auto;
//   width: 21vw;
//   padding-bottom: 5px;
//   padding-left: 5px;
//   @media screen and (max-width: ${windowWidthBreakpoint}) {
//     width: 31vw;
//   }
// `;

const VerticalPanel = () => {
  return (
    <CustomVerticalSetter>
      <LogoContainer>
        <LogoAnchor
          href="https://unhabitat.org"
          title="UN Habitat"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo />
        </LogoAnchor>
        <Title>COVID-19 Readiness & Response</Title>
      </LogoContainer>
      <IndividualVerticalCardWrapper>
        {Object.values(iframeLink)[0] ? (
          <Panel showIcons>
            {/* <Indicators key={'readiness'} cardStyle="readiness" /> */}
            <ControllableIFrame />
          </Panel>
        ) : null}
      </IndividualVerticalCardWrapper>

      <IndividualVerticalCardWrapper>
        <Panel>
          <Indicators key={'responsiveness'} cardStyle="responsiveness" />
        </Panel>
      </IndividualVerticalCardWrapper>

      <CornerCardWrapper>
        <Panel>
          <Impact />
        </Panel>
      </CornerCardWrapper>
    </CustomVerticalSetter>
  );
};

export default VerticalPanel;
