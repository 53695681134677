import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';

import styled from 'styled-components';
import Datatable from '../Datatable';
import AdvancedDataTable from '../AdvancedDatatable';
import Expand from '../../assets/expand.svg';
import {
  footerHeight,
  footerHeightShrinked,
  windowHeightBreakpoint
} from '../../helpers/StyleHelper';
import './sidebar.scss';

const ExpandButton = styled.div`
  width: 16px;
  height: 16px;
  background-color: #2d2f36;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 24px;
  z-index: 100;
  background-image: url(${Expand});
  background-size: 100% 100%;
`;

const FullwidthContainer = styled.div`
  width: 100vw;
  height: calc(100vh - ${footerHeight});
  background-color: #222529;
  position: fixed;
  @media (max-height: ${windowHeightBreakpoint}) {
    height: calc(100vh - ${footerHeightShrinked});
  }
`;

const Sidebar = () => {
  const [fullscreen, setFullscreen] = React.useState(false);

  return (
    <div
      className={
        fullscreen ? 'sidebar sidebar--fullscreen' : 'sidebar sidebar--show'
      }
    >
      <button type="button" className="sidebar__back" />
      <Scrollbar className="sidebar__scroll scroll">
        <div className="sidebar__wrapper sidebar__wrapper--desktop">
          <React.Fragment>
            <ExpandButton onClick={() => setFullscreen(!fullscreen)} />
            {fullscreen ? (
              <FullwidthContainer>
                <AdvancedDataTable />
              </FullwidthContainer>
            ) : (
              <Datatable />
            )}
          </React.Fragment>
        </div>
      </Scrollbar>
    </div>
  );
};

export default Sidebar;
