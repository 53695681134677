import ResponseColors from './ResponseColors';

const DetermineResponseColorScheme = (score) => {
  const DoughnutColorSchemes = {
    red: [ResponseColors[0]],
    orange1: [ResponseColors[1]],
    orange2: [ResponseColors[2]],
    orange3: [ResponseColors[3]],
    yellow: [ResponseColors[4]],
    green: [ResponseColors[5]],
    grey: [ResponseColors[6]],
    blue: [ResponseColors[7]],
    card: [ResponseColors[8]]
  };

  if (score > 0 && score <= 50) {
    return DoughnutColorSchemes.red;
  }
  if (score >= 51 && score <= 60) {
    return DoughnutColorSchemes.orange1;
  }
  if (score >= 61 && score <= 65) {
    return DoughnutColorSchemes.orange2;
  }
  if (score >= 66 && score <= 70) {
    return DoughnutColorSchemes.orange3;
  }
  if (score >= 71 && score <= 80) {
    return DoughnutColorSchemes.yellow;
  }
  if (score >= 81 && score <= 100) {
    return DoughnutColorSchemes.green;
  }
  if (score === '--') {
    return DoughnutColorSchemes.grey;
  }
  if (score === 'blue') {
    return DoughnutColorSchemes.blue;
  }
  if (score === 'orange') {
    return DoughnutColorSchemes.card;
  }
};

export default DetermineResponseColorScheme;
