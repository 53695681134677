import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import globe from '../images/globe.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CityContext } from '../../../../context/CityProvider';
import WorldData from '../../../../data/WorldData';
import CountryData from '../../../../data/CountryData';
import CityData from '../../../../data/CityData';
import {
  StyledScrollbarCSS,
  windowHeightBreakpoint
} from '../../../../helpers/StyleHelper';

const Container = styled.div`
  height: 240px;
  width: 100%;
  background-color: #31353d;
  border-radius: 8px;
  overflow: scroll;
  ${StyledScrollbarCSS} @media screen and
    (max-height: ${windowHeightBreakpoint}) {
    height: 165px;
  }
`;
const CardHeaderContainer = styled.div`
  padding: 5px;
`;
const CardHeader = styled.div`
  height: 34.5px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: white;
  padding-left: 10px;
  box-sizing: border-box;
  @media screen and (max-height: ${windowHeightBreakpoint}) {
    height: unset;
    font-size: 14px;
  }
`;
const Icon = styled.div`
  width: 26px;
  height: 26px;
  background-image: url(${(props) => props.image});
  background-size: 100% 100%;
  margin-right: 10px;
  @media screen and (max-height: ${windowHeightBreakpoint}) {
    width: 18px;
    height: 18px;
  }
`;

const useStyles = makeStyles({
  impactTable: {
    width: '100%'
  },
  column: {
    height: '33px',
    color: 'white',
    borderBottom: 0,
    textAlign: 'left',
    fontSize: '12px',
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
    '&:last-child': {
      paddingRight: '10px'
    },
    '@media screen and (max-height: 800px)': {
      height: '23px'
    }
  },
  redLabel: {
    backgroundColor: '#e22d4e',
    color: 'white',
    fontWeight: 'bold',
    padding: '2px 7px 1px',
    borderRadius: '2px',
    '@media screen and (max-height: 800px)': {
      padding: '0px 5px 0px'
    }
  },
  rowHead: {
    borderBottom: '1px solid #989A9E'
  },
  rowHeader: {
    color: '#989A9E',
    fontSize: '12px',
    lineHeight: '18px',
    height: '32px',
    backgroundColor: '#31353D !important',
    borderBottom: 0,
    padding: 0,
    textAlign: 'left',
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
    '&:last-child': {
      paddingRight: '10px'
    },
    '@media screen and (max-height: 800px)': {
      height: '23px'
    }
  },
  rowContainer: {
    '&:nth-child(odd)': {
      backgroundColor: '#2C2F36'
    },
    '&:nth-child(even)': {
      backgroundColor: '#31353D'
    }
  }
});

function createData(columnHeader, world, country, city) {
  return { columnHeader, world, country, city };
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Cases = () => {
  const classes = useStyles();
  const { cityState } = React.useContext(CityContext);
  const [renderTable, setRenderTable] = React.useState(false);
  const [output, setOutput] = React.useState(null);
  const [overrideLabel, setOverrideLabel] = React.useState('City');

  const getValue = (item) => {
    let cleanedItem = '';

    if (item === 0) {
      cleanedItem = 0;
    } else {
      if (item) {
        cleanedItem = item.toFixed(2);
        const DecimalPoints = cleanedItem.slice(-2);
        if (DecimalPoints === '00') {
          cleanedItem = numberWithCommas(
            cleanedItem.substring(0, cleanedItem.length - 3)
          );
        }
      } else {
        cleanedItem = '--';
      }
    }

    return cleanedItem;
  };

  React.useEffect(() => {
    setRenderTable(false);
    let CasesColumn = [
      createData(
        'Cases',
        `${numberWithCommas(WorldData[0].ConfirmedCases)}`,
        '--',
        '--'
      ),
      createData(
        'Lives Lost',
        `${numberWithCommas(WorldData[0].LivesLost)}`,
        '--',
        '--'
      ),
      createData(
        'Mortality Rates',
        `${WorldData[0].Death.toFixed(2)}%`,
        '--',
        '--'
      ),
      createData(
        'Cases/100k',
        `${WorldData[0].Cases1000.toFixed(2)}`,
        '--',
        '--'
      ),
      createData(
        'Lives Lost/100k',
        `${WorldData[0].Deaths1000.toFixed(2)}`,
        '--',
        '--'
      )
    ];
    if (
      cityState &&
      cityState.City &&
      cityState.City.properties &&
      cityState.City.properties.city
    ) {
      CityData.forEach((city) => {
        if (city.id === cityState.City.properties.cityId) {
          CasesColumn[0].city = getValue(city.ConfirmedCases);
          CasesColumn[1].city = getValue(city.LivesLost);
          CasesColumn[2].city =
            getValue(city.Death) === '--'
              ? `${getValue(city.Death)}`
              : `${getValue(city.Death)}%`;
          CasesColumn[3].city = getValue(city.Cases1000);
          CasesColumn[4].city = getValue(city.Deaths1000);

          city.OverrideLabel
            ? setOverrideLabel(city.OverrideLabel)
            : setOverrideLabel('City');
        }
      });
      CountryData.forEach((country) => {
        if (country.Country === cityState.City.properties.country) {
          CasesColumn[0].country = numberWithCommas(country.ConfirmedCases);
          CasesColumn[1].country = numberWithCommas(country.LivesLost);
          CasesColumn[2].country = country.Death
            ? `${country.Death.toFixed(2)}%`
            : `${country.Death}%`;
          CasesColumn[3].country = country.Cases1000
            ? country.Cases1000.toFixed(2)
            : country.Cases1000;
          CasesColumn[4].country = country.Deaths1000
            ? country.Deaths1000.toFixed(2)
            : country.Deaths1000;
        }
      });
    }

    setOutput(
      <Table className={classes.impactTable} key={Math.random()}>
        <TableHead className={classes.rowHead}>
          <TableRow>
            <TableCell className={classes.rowHeader}></TableCell>
            <TableCell className={classes.rowHeader}>World</TableCell>
            <TableCell className={classes.rowHeader}>Country</TableCell>
            <TableCell className={classes.rowHeader} key={overrideLabel}>
              {overrideLabel}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {CasesColumn.map((column, index) => {
            return (
              <TableRow
                key={`${column.columnHeader}:${column.world}:${column.country}:${column.city}:${index}`}
                className={classes.rowContainer}
              >
                <TableCell
                  key={`${index}:${column.columnHeader}`}
                  component="th"
                  scope="row"
                  className={classes.column}
                >
                  {column.columnHeader}
                </TableCell>
                <TableCell
                  key={`${index}:${column.world}`}
                  align="center"
                  className={classes.column}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={index === 2 ? classes.redLabel : ''}>
                      {column.world}
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  key={`${index}:${column.country}`}
                  align="center"
                  className={classes.column}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={index === 2 ? classes.redLabel : ''}>
                      {column.country}
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  key={`:${index}:${column.city}`}
                  align="center"
                  className={classes.column}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={index === 2 ? classes.redLabel : ''}>
                      {column.city}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
    setRenderTable(true);
    // eslint-disable-next-line
  }, [cityState, overrideLabel]);

  return (
    <Container style={{ height: isMobile ? 'auto' : '' }}>
      <CardHeaderContainer>
        <CardHeader>
          <Icon image={globe} />
          Impact
        </CardHeader>
      </CardHeaderContainer>
      {renderTable ? output : null}
    </Container>
  );
};
export default Cases;
