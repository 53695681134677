import React from 'react';
import Panel from '../../../Panel';
import CumulativeGraphs from '../../PanelTypes/CumulativeGraphs';
import FourteenDayAverageGraphs from '../../PanelTypes/FourteenDayAverageGraphs';

const LivesLostGraph = () => {
  const [livesLostToggle, setLivesLostToggle] = React.useState('14Day');

  const handleLivesLostToggle = (value) => {
    setLivesLostToggle(value);
  };

  return (
    <Panel showIcons>
      {livesLostToggle === '14Day' ? (
        <FourteenDayAverageGraphs
          cardStyle="livesLost"
          handleToggle={handleLivesLostToggle}
          toggle={livesLostToggle}
        />
      ) : null}
      {livesLostToggle === 'AllTime' ? (
        <CumulativeGraphs
          cardStyle="livesLost"
          handleToggle={handleLivesLostToggle}
          toggle={livesLostToggle}
        />
      ) : null}
    </Panel>
  );
};

export default React.memo(LivesLostGraph);
