export const footerHeight = '40px';
export const footerHeightShrinked = '30px'; // height when screenH <= 900

export const bottomPanelHeight = '240px';
export const bottomPanelHeightShrinked = '165px'; // height when screenH <= 900

export const windowHeightBreakpoint = '800px';
export const windowWidthBreakpoint = '1200px';

export const StyledScrollbar = {
  '&:hover': {
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: 'rgba(255,255,255,.15) transparent'
  },
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255,255,255,.15)',
    border: '2px solid transparent',
    borderRadius: '4px'
  }
};
export const StyledScrollbarCSS = `
  &:hover: {
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: rgba(255,255,255,.15) transparent;
  }
  &::-webkit-scrollbar: {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track: {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb: {
    background-color: rgba(255,255,255,.15);
    border: 2px solid transparent;
    border-radius: 4px;
  }


`;
