import React, { useRef } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

import MUIDataTable from 'mui-datatables';
import DoughNutChart from '../DoughnutChart';
import { CityContext } from '../../context/CityProvider';
import { MobileContext } from '../../context/MobileProvider';
import { CityScoresContext } from '../../context/CityScoresProvider';
import { PhasedContentContext } from '../../context/PhasedContentProvider';
import DetermineDoughnutColor from '../../helpers/DetermineDoughnutColor';
import DetermineLastUpdate from '../../helpers/DetermineLastUpdate';
import { StyledScrollbar } from '../../helpers/StyleHelper';
import DetermineTrendingColor from '../../helpers/DetermineTrendingColor';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const Datatable = () => {
  const { cityScoresState } = React.useContext(CityScoresContext);
  const { phasedContentState } = React.useContext(PhasedContentContext);
  const [data, setData] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [filter, setFilter] = React.useState([]);
  const [activeColumn, setActiveColumn] = React.useState({
    column: 2,
    sort: 'desc'
  });
  const { dispatchCityState } = React.useContext(CityContext);
  const { dispatchMobileState } = React.useContext(MobileContext);
  const tableRef = useRef(null);

  const handleRowClick = (index) => {
    dispatchCityState({ type: 'update', data: index[0] });
    ReactGA.event({
      category: 'City Selected',
      action: `${index[0].properties.city}`
    });
    if (isMobile) {
      dispatchMobileState({ type: 'switchTab', data: 'readiness' });
    }
  };

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: '#22252C',
            color: 'white',
            width: '100%',
            height: '100% !important',
            overflow: 'hidden'
          }
        },
        MuiTable: {
          root: {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            bottom: '0',
            height: isMobile ? 'unset' : 'auto'
          }
        },
        MUIDataTableBodyRow: {
          root: {
            width: '100%',
            '&:nth-child(odd)': {
              backgroundColor: '#2C2F36'
            },
            '&:nth-child(even)': {
              backgroundColor: '#31353D'
            }
          }
        },
        MUIDataTableToolbar: {
          root: {
            display: 'flex !important',
            paddingRight: '50px'
          },
          titleRoot: {
            display: 'flex'
          },
          actions: {
            paddingRight: '10px',
            textAlign: 'right !important'
          },
          left: {
            // marginLeft: '40px !important'
          },
          icon: {
            color: 'white'
          },
          iconActive: {
            color: 'white'
          },
          filterPaper: {
            height: 'auto !important'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            color: 'white',
            textAlign: 'center',
            padding: '0 5px',

            '&:first-child': {
              paddingLeft: '10px'
            }
          },
          sortActive: { color: 'white' },
          sortAction: { color: 'white', alignItems: 'center' },
          toolButton: {
            justifyContent: 'center'
          }
        },
        MuiTableCell: {
          head: {
            backgroundColor: '#2C2F36 !important',
            color: 'white'
          },
          root: {
            padding: '0 5px',
            borderBottom: '0'
          }
        },

        MUIDataTableBodyCell: {
          root: {
            padding: '0 5px',
            color: 'white !important',
            textAlign: 'center'
          }
        },
        MuiTableFooter: {
          root: {
            width: '100%'
          }
        },
        MUIDataTablePagination: {
          root: {
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }
        },
        MUIDataTable: {
          paper: {
            height: '100% !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'stretch'
          },
          responsiveScroll: {
            height: '100%',
            ...StyledScrollbar,
            maxHeight: 'unset !important',
            flex: 1,
            flexGrow: 1
          }
        },
        MuiInput: {
          formControl: {
            color: 'white'
          },
          underline: {
            '&:hover:before': {
              borderBottom: '0px solid white !important'
            },
            '&:before': {
              borderBottom: '0px solid white !important'
            },
            '&:after': {
              borderBottom: '0px solid white'
            }
          }
        },
        MuiInputBase: {
          input: {
            background: 'lightgrey',
            color: '#31353d',
            padding: '6px 10px 7px'
          }
        },
        MUIDataTableViewCol: {
          title: {
            color: 'white'
          },
          label: {
            color: 'white'
          }
        },
        MUIDataTableSearch: {
          main: {
            alignItems: 'center'
          },
          searchIcon: {
            color: 'white',
            marginRight: '8px !important',
            display: 'none'
          },
          clearIcon: { color: 'white' }
        },
        MuiTableSortLabel: {
          // icon: { display: 'none' },
          active: { color: 'white !important' },
          iconDirectionDesc: { color: 'white !important' },
          iconDirectionAsc: { color: 'white !important' }
        },
        MUIDataTableFilter: {
          root: {
            backgroundColor: '#2C2F36'
          },
          title: {
            color: 'rgba(255, 255, 255, 0.87)'
          }
        },
        MuiFormLabel: {
          root: { color: 'rgba(255, 255, 255, 0.5) !important' }
        }
      }
    });

  React.useEffect(() => {
    const row = [];

    if (Object.keys(cityScoresState.CityScores.features).length > 0) {
      cityScoresState.CityScores.features.forEach((index) => {
        const {
          city,
          country,
          overallResponsiveness,
          publicHealthCapacity,
          societalStrength,
          economicAbility,
          infrastructure,
          nationalCollaborativeWill,
          healthcareResponse,
          economicResponse,
          socialResponse,
          supplyManagementResponse,
          new_cases_per100k_14d_change_percent
        } = index.properties;

        const wholeStore = index;

        row.push([
          wholeStore,
          wholeStore,
          Number(new_cases_per100k_14d_change_percent),
          overallResponsiveness,
          city,
          country,
          publicHealthCapacity,
          societalStrength,
          economicAbility,
          infrastructure,
          nationalCollaborativeWill,
          healthcareResponse,
          economicResponse,
          socialResponse,
          supplyManagementResponse
        ]);
      });

      setData(row);
    }
  }, [cityScoresState, phasedContentState.PhasedContent]);

  const columns = [
    {
      name: 'Data',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, x) => {
          return (
            <div
              style={{
                height: '54px',
                width: '5px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  height: '54px',
                  width: '5px',
                  backgroundColor: DetermineTrendingColor(
                    value.properties.new_cases_per100k_14d_change_percent
                  )
                }}
              ></div>
            </div>
          );
        }
      }
    },
    {
      name: 'Trending Percent',
      options: {
        sortDirection: activeColumn.column === 2 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        customBodyRender: (value, tableCell) => {
          return (
            <div
              style={{
                height: '54px',
                width: '54px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  height: '48px',
                  width: '48px',
                  marginLeft: '5px'
                }}
              >
                <DoughNutChart
                  dataWeight={'trending'}
                  score={value}
                  id={tableCell}
                  overlaySize="12px"
                  overlayColor="white"
                />
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'Response Score',
      options: {
        sortDirection: activeColumn.column === 3 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        customBodyRender: (value, x) => {
          return (
            <div
              style={{
                height: '54px',
                width: '50px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  height: '48px',
                  width: '48px',
                  marginLeft: '5px'
                }}
              >
                <DoughNutChart
                  dataWeight={'responsiveness'}
                  score={value}
                  id={x}
                  overlaySize="20px"
                  overlayColor="white"
                  doughNutColors={DetermineDoughnutColor('responsiveness')}
                />
              </div>
            </div>
          );
        }
      }
    },

    {
      name: 'City',
      options: {
        sortDirection: activeColumn.column === 4 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Country',
      options: {
        sortDirection: activeColumn.column === 5 ? activeColumn.sort : null,
        filterList: filter,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Public Health Capacity',
      options: {
        sortDirection: activeColumn.column === 6 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Societal Strength',
      options: {
        sortDirection: activeColumn.column === 7 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Economic Ability',
      options: {
        sortDirection: activeColumn.column === 8 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Infrastructure',
      options: {
        sortDirection: activeColumn.column === 9 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Collaborative Will',
      options: {
        sortDirection: activeColumn.column === 10 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Spread Response',
      options: {
        sortDirection: activeColumn.column === 11 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Treatment Response',
      options: {
        sortDirection: activeColumn.column === 12 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Economic Response',
      options: {
        sortDirection: activeColumn.column === 13 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Supply Chain Response',
      options: {
        sortDirection: activeColumn.column === 13 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    }
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'scroll',
    rowsPerPageOptions: [],
    rowsPerPage: 50,
    print: false,
    download: isMobile ? false : true,
    selectableRowsHeader: false,
    selectableRows: 'none',
    viewColumns: false,
    sort: true,
    page: 0,
    downloadOptions: {
      filename: `${DetermineLastUpdate()}.csv`,
      separator: ';'
    },
    searchText,
    onColumnSortChange: () => {
      tableRef.current.changePage(0);
    },
    onFilterChange: (columnChanged, filterList) => {
      setFilter(filterList[4]);
    },
    onSearchChange: (searchText) => setSearchText(searchText),
    onRowClick: (index, i) => handleRowClick(index, i),
    onChangePage: () => {
      const table = document.querySelector('table');
      table.scrollIntoView(true);
    },
    onTableChange: (action, tableState) => {
      if (action === 'sort') {
        if (
          tableState &&
          tableState.announceText &&
          tableState.announceText.includes('descending')
        ) {
          setActiveColumn({ column: tableState.activeColumn, sort: 'desc' });
        }
        if (
          tableState &&
          tableState.announceText &&
          tableState.announceText.includes('ascending')
        ) {
          setActiveColumn({ column: tableState.activeColumn, sort: 'asc' });
        }
      }
    }
  };
  return (
    <Container>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          innerRef={tableRef}
          title="City Index"
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </Container>
  );
};

export default Datatable;
