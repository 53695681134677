import React from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';

import ArrowExpand from 'mdi-react/ArrowExpandIcon';
import { PanelContext } from '../../context/PanelProvider';
import './panel.scss';

const Panel = ({ children, showIcons }) => {
  const [collapse] = React.useState(true);
  const [expand, setExpand] = React.useState(false);
  const { dispatchPanelState } = React.useContext(PanelContext);

  const onExpand = () => {
    setExpand(!expand);
    dispatchPanelState({ type: 'update', data: expand });
  };

  return (
    <Card
      className={`panel : ''}
      ${collapse ? '' : ' panel--collapse'}
      ${expand ? ' panel--expand' : ''}
    `}
    >
      <CardBody className="panel__body">
        {showIcons ? (
          <div className="panel__btns">
            <button
              className="panel__btn"
              type="button"
              onClick={() => onExpand()}
            >
              <ArrowExpand />
            </button>
          </div>
        ) : null}

        <Collapse isOpen={collapse}>
          <div className={'panel__content'}>{children}</div>
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default Panel;
