import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MobileContext } from '../../context/MobileProvider';
import { CityContext } from '../../context/CityProvider';
import { CityScoresContext } from '../../context/CityScoresProvider';

import CityIndex from './MobileViews/CityIndex';
import Responsiveness from './MobileViews/Responsiveness';
import Impact from './MobileViews/Impact';

import citiIndexSelected from './images/citiIndexSelected.svg';
import citiIndexUnselected from './images/citiIndexUnselected.svg';
import mapIconSelected from './images/mapSelected.svg';
import mapIconUnselected from './images/mapUnselected.svg';
import readinessIconSelected from './images/readinessSelected.svg';
import readinessIconUnselected from './images/readinessUnselected.svg';
import impactIconSelected from './images/impactSelected.svg';
import impactIconUnselected from './images/impactUnselected.svg';
import UNlogo from './images/mobileLogo@4x.png';
import MapLegend from '../MapLegend';
import Map from '../MapboxGl';

const UNlogoImageW = 680;
const UNlogoImageH = 104;
const bottomNavHeight = '80px';

const Container = styled.div`
  height: ${(props) => (props.vh ? `${props.vh * 100}px` : '100vh')};
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1c2026;
`;

const BottomBar = styled.div`
  height: ${bottomNavHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden; // to prevent clickable area overflows
`;
const LogoBanner = styled.div`
  width: 192px;
  height: auto;
  margin: 25px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Logo = styled.div`
  width: 100%;
  height: 0;
  padding-top: ${(UNlogoImageH / UNlogoImageW) * 100}%;
  background-image: url(${UNlogo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const Title = styled.h1`
  margin: 0 auto 20px;
  font-size: 12px;
  color: white;
`;

const ContentViewer = styled.div`
  height: calc(
    ${(props) => (props.vh ? `${props.vh * 100}px` : '100vh')} - 106px -
      ${bottomNavHeight}
  );
  position: relative;
  width: 100%;
  padding-bottom: ${(props) => props.paddingBottom};
`;

const Panel = styled.div`
  height: 100%;
  width: 25%;
`;

const Icon = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  height: 70%;
  width: 100%;
`;
const PanelName = styled.div`
  width: 100%;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileContainer = () => {
  const { dispatchMobileState, MobileState } = React.useContext(MobileContext);
  const { cityScoresState } = React.useContext(CityScoresContext);
  const { cityState, dispatchCityState } = React.useContext(CityContext);
  const [popupInfo, setPopupInfo] = useState(false);
  const { Tab } = MobileState;

  const [vh, setVh] = useState(
    typeof window !== 'undefined' && window.innerHeight / 100
  );
  const setViewing = (view) => {
    dispatchMobileState({ type: 'switchTab', data: view });
  };

  React.useEffect(() => {
    if (cityState.FirstLoad && cityScoresState?.CityScores?.features) {
      cityScoresState.CityScores.features.forEach((individualCity) => {
        if (individualCity.properties.city === 'Los Angeles') {
          dispatchCityState({ type: 'update', data: individualCity });
        }
      });
    }
    /* eslint-disable-next-line*/
  }, [cityScoresState, cityState.FirstLoad]);

  const ViewComponent = (Tab) => {
    switch (Tab) {
      case 'index':
        return <CityIndex setViewing={setViewing} />;
      case 'map':
        return (
          <>
            <MapLegend isMobileView={true} />
            <Map popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
          </>
        );
      case 'readiness':
        return <Responsiveness />;
      case 'impact':
        return <Impact />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight / 100);
    };
    window && handleResize();
    window && window.addEventListener('resize', handleResize);
    return () => {
      window && window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container vh={vh}>
      <a
        href="https://unhabitat.org"
        title="UN Habitat"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'inline-block' }}
      >
        <LogoBanner>
          <Logo />
        </LogoBanner>
      </a>
      <Title>COVID-19 Readiness & Response</Title>
      <ContentViewer paddingBottom={Tab === 'map' ? 120 : 0} vh={vh}>
        {ViewComponent(Tab)}
      </ContentViewer>
      <BottomBar>
        <Panel onClick={() => setViewing('map')}>
          <Icon image={Tab === 'map' ? mapIconSelected : mapIconUnselected} />
          <PanelName>Map</PanelName>
        </Panel>
        <Panel onClick={() => setViewing('index')}>
          <Icon
            image={Tab === 'index' ? citiIndexSelected : citiIndexUnselected}
          />
          <PanelName>Index</PanelName>
        </Panel>

        <Panel onClick={() => setViewing('readiness')}>
          <Icon
            image={
              Tab === 'readiness'
                ? readinessIconSelected
                : readinessIconUnselected
            }
          />
          <PanelName>Responsiveness</PanelName>
        </Panel>
        <Panel onClick={() => setViewing('impact')}>
          <Icon
            image={Tab === 'impact' ? impactIconSelected : impactIconUnselected}
          />
          <PanelName>Impact</PanelName>
        </Panel>
      </BottomBar>
    </Container>
  );
};

export default MobileContainer;
