import React from 'react';
import styled from 'styled-components';
import DetermineDoughnutColor from '../../../../helpers/DetermineDoughnutColor';
import DetermineResponseColorScheme from '../../../../helpers/DetermineResponseColorScheme';

import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import { CityContext } from '../../../../context/CityProvider';
import Barchart from './Barchart';
import DoughNutChart from '../../../DoughnutChart';

const DisplayOverlay = styled.div`
  height: 30%;
  width: 100%;
  background-color: #31353d;
  padding: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

const Country = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #adaeb1;
`;
const City = styled.div`
  font-size: 18px;
  line-height: 26px;
`;

const useStyles = makeStyles({
  table: {
    width: '100%',
    height: '100%',
    backgroundColor: '#31353D !important',
    overflow: 'scroll',
    borderRadius: '8px'
  },

  row1: {
    fontSize: '12px',
    width: '55%',
    height: '25px',
    padding: 0,
    color: 'white',
    borderBottom: 0,
    textAlign: 'left',
    paddingLeft: '10px'
  },
  row2: {
    fontSize: '12px',
    width: '45%',
    height: '25px',
    padding: 0,
    color: 'white',
    borderBottom: 0,
    textAlign: 'left',
    paddingLeft: '10px'
  },
  rowHead: {
    borderBottom: '1px solid #474A51',
    display: 'table-header-group !important'
  },

  rowHeader: {
    color: '#989A9E',
    fontSize: '12px',
    lineHeight: '18px',
    width: '55%',
    height: '32px',
    backgroundColor: '#31353D !important',
    borderBottom: 0,
    padding: 0,
    textAlign: 'left',
    paddingLeft: '10px'
  },
  rowHeader2: {
    color: '#989A9E',
    fontSize: '12px',
    lineHeight: '18px',
    width: '45%',
    height: '32px',
    backgroundColor: '#31353D !important',
    borderBottom: 0,
    padding: 0,
    textAlign: 'left',
    paddingLeft: '10px'
  },
  rowContainer: {
    '&:nth-child(odd)': {
      backgroundColor: '#2C2F36'
    },
    '&:nth-child(even)': {
      backgroundColor: '#31353D'
    }
  }
});

const Indicators = ({ cardStyle }) => {
  const classes = useStyles();
  const { cityState } = React.useContext(CityContext);
  const [readinessData, setReadinessData] = React.useState([]);
  const [responsivenessData, setResponsivenessData] = React.useState([]);

  function createData(indicator, score) {
    return { indicator, score };
  }
  React.useEffect(() => {
    if (cityState && cityState.City && cityState.City.properties) {
      const readinessRows = [
        createData(
          'Public Health Capacity',
          cityState.City.properties.publicHealthCapacity || '--'
        ),
        createData(
          'Societal Strength',
          cityState.City.properties.societalStrength || '--'
        ),
        createData(
          'Economic Ability',
          cityState.City.properties.economicAbility || '--'
        ),
        createData(
          'Infrastructure',
          cityState.City.properties.infrastructure || '--'
        ),
        createData(
          'Collaborative Will',
          cityState.City.properties.nationalCollaborativeWill || '--'
        )
      ];
      setReadinessData(readinessRows);

      const responseRows = [
        createData(
          'Spread Response',
          cityState.City.properties.healthcareResponse || '--'
        ),
        createData(
          'Treatment Response',
          cityState.City.properties.economicResponse || '--'
        ),
        createData(
          'Economic Response',
          cityState.City.properties.socialResponse || '--'
        ),
        createData(
          'Supply Chain Response',
          cityState.City.properties.supplyManagementResponse || '--'
        )
      ];
      setResponsivenessData(responseRows);
    }
  }, [cityState]);

  return (
    <TableContainer className={classes.table} component={Paper}>
      <DisplayOverlay>
        {cityState.City.properties && cityState.City.geometry ? (
          <Wrapper>
            <div style={{ height: '65px', width: '65px', marginRight: '20px' }}>
              <DoughNutChart
                dataWeight={cardStyle}
                DoughNutData={cityState.City}
                overlayColor="white"
                overlaySize="20px"
                score={
                  cardStyle === 'readiness'
                    ? cityState.City.properties.overallReadiness
                    : cityState.City.properties.overallResponsiveness
                }
                doughNutColors={DetermineDoughnutColor(cardStyle)}
              />
            </div>
            <VerticalWrapper>
              {cardStyle === 'readiness' ? (
                <div
                  style={{
                    lineHeight: '16px',
                    fontSize: '14px',
                    color: `${DetermineResponseColorScheme('blue')}`
                  }}
                >
                  {'Readiness'}
                </div>
              ) : null}

              {cardStyle === 'responsiveness' ? (
                <div
                  style={{
                    lineHeight: '16px',
                    fontSize: '14px',
                    color: `${DetermineResponseColorScheme('orange')}`
                  }}
                >
                  {'Responsiveness'}
                </div>
              ) : null}

              <City>{cityState.City.properties.city}</City>
              <Country>{cityState.City.properties.country}</Country>
            </VerticalWrapper>
          </Wrapper>
        ) : null}
      </DisplayOverlay>
      <Table aria-label="simple table">
        <TableHead className={classes.rowHead}>
          <TableRow>
            <TableCell className={classes.rowHeader}>Considerations</TableCell>
            <TableCell className={classes.rowHeader2}>Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cardStyle === 'readiness'
            ? readinessData.map((row, index) => (
                <TableRow
                  key={`${row.indicator}:${row.score}`}
                  className={classes.rowContainer}
                >
                  <TableCell
                    key={row.indicator}
                    component="th"
                    scope="row"
                    className={classes.row1}
                  >
                    {row.indicator}
                  </TableCell>
                  <TableCell align="right" className={classes.row2}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <Barchart
                        key={row.score}
                        data={row.score}
                        barColor={DetermineResponseColorScheme('blue')}
                      />
                      <div style={{ marginLeft: '10px', marginRight: '5px' }}>
                        {row.score}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            : responsivenessData.map((row) => {
                return (
                  <TableRow
                    key={`${row.indicator}:${row.score}`}
                    className={classes.rowContainer}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.row1}
                    >
                      {row.indicator}
                    </TableCell>
                    <TableCell align="center" className={classes.row2}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}
                      >
                        {cityState &&
                        cityState.City &&
                        cityState.City.properties &&
                        cityState.City.properties.overallResponsiveness ? (
                          <Barchart
                            key={row.score}
                            data={row.score}
                            barColor={DetermineResponseColorScheme('orange')}
                          />
                        ) : null}

                        <div style={{ marginLeft: '10px', marginRight: '5px' }}>
                          {row.score}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Indicators;
