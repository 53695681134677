import React from 'react';
import Panel from '../../../Panel';
import CumulativeGraphs from '../../PanelTypes/CumulativeGraphs';
import FourteenDayAverageGraphs from '../../PanelTypes/FourteenDayAverageGraphs';

const CasesGraph = () => {
  const [casesToggle, setCasesToggle] = React.useState('14Day');
  const handleCasesToggle = (value) => {
    setCasesToggle(value);
  };
  return (
    <Panel showIcons>
      {casesToggle === '14Day' ? (
        <FourteenDayAverageGraphs
          cardStyle="confirmedCases"
          handleToggle={handleCasesToggle}
          toggle={casesToggle}
        />
      ) : null}
      {casesToggle === 'AllTime' ? (
        <CumulativeGraphs
          cardStyle="confirmedCases"
          handleToggle={handleCasesToggle}
          toggle={casesToggle}
        />
      ) : null}
    </Panel>
  );
};

export default React.memo(CasesGraph);
