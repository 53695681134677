import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import DetermineTrendingColor from '../../helpers/DetermineTrendingColor';

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  font-size: ${(props) => props.overlaySize};
  color: white;
`;

const innerCircleOptions = {
  aspectRatio: 1,
  legend: {
    display: false
  },
  tooltips: {
    enabled: false
  },
  cutoutPercentage: 70,
  circumference: 2 * Math.PI,
  animation: {
    duration: 0
  },
  elements: {
    arc: {
      borderWidth: 0
    }
  }
};
const DoughnutChart = ({ overlaySize, score, doughNutColors, dataWeight }) => {
  const [data, setData] = React.useState({});

  const datasetKeyProvider = () => {
    return Math.random();
  };

  React.useEffect(() => {
    const indicator1 =
      dataWeight === 'responsivness' ? score * 0.4 : score * 0.35;
    const indicator2 =
      dataWeight === 'responsivness' ? score * 0.3 : score * 0.25;
    const indicator3 =
      dataWeight === 'responsivness' ? score * 0.15 : score * 0.15;
    const indicator4 =
      dataWeight === 'responsivness' ? score * 0.15 : score * 0.15;
    const indicator5 = dataWeight === 'responsivness' ? score * 0.1 : null;
    const total =
      100 - indicator1 - indicator2 - indicator3 - indicator4 - indicator5;

    const weightedScores = {
      datasets: [
        {
          data: [
            indicator1,
            indicator2,
            indicator3,
            indicator4,
            dataWeight === 'responsivness' ? null : indicator5,
            total
          ],
          backgroundColor: doughNutColors,
          borderWidth: []
        }
      ]
    };

    if (dataWeight === 'trending') {
      setData({
        datasets: [
          {
            data: [100],
            backgroundColor: DetermineTrendingColor(score),
            borderWidth: []
          }
        ]
      });
    }

    if (dataWeight === 'trending' && score < -10000) {
      setData({
        datasets: [
          {
            data: [100],
            backgroundColor: DetermineTrendingColor(null),
            borderWidth: []
          }
        ]
      });
    }

    if (dataWeight === 'readiness' || dataWeight === 'responsiveness') {
      setData(weightedScores);
    }
  }, [score, doughNutColors, dataWeight]);

  const getScoreFormatting = () => {
    if (dataWeight === 'trending' && score >= 1000000000000) {
      return `NEW`;
    }

    if (dataWeight === 'trending' && score < -10000) {
      return '--';
    }
    if (dataWeight === 'trending' && score > 0 && score < 10) {
      return `+${score.toFixed(1)}`;
    }

    if (dataWeight === 'trending' && score > 10) {
      return `>10`;
    }

    if (dataWeight === 'trending' && score > -10 && score < 0) {
      return `${score.toFixed(1)}`;
    }
    if (dataWeight === 'trending' && score === 0) {
      return `${score}.0`;
    }
    if (dataWeight === 'trending' && score < -10) {
      return `<-10`;
    } else {
      return score;
    }
  };
  return (
    <Container>
      <Doughnut
        label={datasetKeyProvider()}
        height={null}
        width={null}
        data={data}
        options={innerCircleOptions}
      />
      <Overlay overlayColor="white" overlaySize={overlaySize}>
        {getScoreFormatting()}
      </Overlay>
    </Container>
  );
};

export default DoughnutChart;
