import React from 'react';
import styled from 'styled-components';

import {
  footerHeight,
  footerHeightShrinked,
  windowHeightBreakpoint,
  windowWidthBreakpoint
} from '../../../helpers/StyleHelper';
import CasesGraph from './CasesGraph';
import LivesLostGraph from './LivesLostGraph';

const CustomHorizontalSetter = styled.div`
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-top: 5px;
  align-items: flex-end;
  right: 19vw;
  box-sizing: border-box;
  bottom: ${footerHeight};
  background-color: #1c1f26;
  @media (max-height: ${windowHeightBreakpoint}) {
    bottom: ${footerHeightShrinked};
  }
  @media screen and (max-width: ${windowWidthBreakpoint}) {
    width: 81vw;
  }
`;

const IndividualHorizontalCardWrapper = styled.div`
  height: auto;
  padding-bottom: 5px;
  width: 28.75vw;
  padding-left: 5px;
  @media screen and (max-width: ${windowWidthBreakpoint}) {
    width: 40.5vw;
  }
`;

const HorizontalPanel = () => {
  return (
    <CustomHorizontalSetter>
      <IndividualHorizontalCardWrapper>
        <CasesGraph />
      </IndividualHorizontalCardWrapper>
      <IndividualHorizontalCardWrapper>
        <LivesLostGraph />
      </IndividualHorizontalCardWrapper>
    </CustomHorizontalSetter>
  );
};

export default HorizontalPanel;
