import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import moment from 'moment';

import virus from '../images/virus.svg';
import flower from '../images/flower.svg';

import { windowHeightBreakpoint } from '../../../../helpers/StyleHelper';
import { CityContext } from '../../../../context/CityProvider';
import { CityScoresContext } from '../../../../context/CityScoresProvider';
import { PanelContext } from '../../../../context/PanelProvider';
import Toggle from '../GraphToggle';
import LoadingReticule from '../../../LoadingReticule';

const parseColorPoints = (data, color) => {
  const array = [];
  data.forEach(() => {
    array.push(color);
  });
  return array;
};

const formatDate = dateString => {
  return moment(dateString.split('.').join('')).format('YY MMM DD');
};

const sortAsc = function (a, b) {
  return parseInt(a.split('.').join('')) - parseInt(b.split('.').join(''));
};

const parseDataPoints = (data, dates, key) => {
  return dates.sort(sortAsc).map(date => {
    if (!data[date]) return null;

    if (data[date][key] === '') {
      return null;
    }

    return data[date][key];
  });
};

const parseDateLabels = (data, format) => {
  return Object.keys(data)
    .sort(sortAsc)
    .map(date => {
      return format ? formatDate(date) : date;
    });
};

function mergeArrays(...arrays) {
  let jointArray = [];

  arrays.forEach(array => {
    jointArray = [...jointArray, ...array];
  });

  return [...new Set([...jointArray])];
}

const FourteenDayAverageGraphs = ({ cardStyle, handleToggle, toggle }) => {
  // grab historical data from context
  const { cityState } = React.useContext(CityContext);
  const { panelState } = React.useContext(PanelContext);
  const { cityScoresState } = React.useContext(CityScoresContext);

  const [loading, setLoading] = React.useState(true);
  const [graphData, setGraphData] = React.useState({});

  React.useEffect(() => {
    if (
      cityScoresState.HistoricalData.countryData &&
      cityScoresState.HistoricalData.cityData &&
      cityState?.City?.properties?.city &&
      cityState?.City?.properties?.country
    ) {
      const { city, country, cityId } = cityState.City.properties;
      const countryJSON =
        cityScoresState.HistoricalData.countryData[country] || {};
      const cityJSON =
        cityScoresState.HistoricalData.cityData[String(cityId) + '.0'] || {};

      let dataset1;
      let dataset2;

      if (cardStyle === 'confirmedCases') {
        dataset1 = 'new_cases_per100k';
      }
      if (cardStyle === 'livesLost') {
        dataset1 = 'new_lives_lost_per100k_14d_avg';
      }

      if (cardStyle === 'confirmedCases') {
        dataset2 = 'new_cases_per100k_14d_avg';
      }
      if (cardStyle === 'livesLost') {
        dataset2 = 'new_lives_lost_per100k_14d_change_percent';
      }

      const dateLabels = mergeArrays(
        parseDateLabels(countryJSON, true),
        parseDateLabels(cityJSON, true)
      );
      const dateKeys = mergeArrays(
        parseDateLabels(countryJSON),
        parseDateLabels(cityJSON)
      );

      const country_overall_cases_per_100k = parseDataPoints(
        countryJSON,
        dateKeys,
        dataset1
      );
      const country_14_day_data = parseDataPoints(
        countryJSON,
        dateKeys,
        dataset2
      );
      const city_overall_cases_per_100k = parseDataPoints(
        cityJSON,
        dateKeys,
        dataset1
      );
      const city_14_day_data = parseDataPoints(cityJSON, dateKeys, dataset2);

      const arrayOfColors1 = parseColorPoints(
        country_overall_cases_per_100k,
        'rgba(92, 193, 156, 0.6)'
      );
      const arrayOfColors2 = parseColorPoints(
        city_overall_cases_per_100k,
        'rgba(214, 88, 217, 0.6)'
      );

      const arrayOfColors3 = parseColorPoints(
        city_overall_cases_per_100k,
        '#D658D9'
      );
      const arrayOfColors4 = parseColorPoints(city_14_day_data, '#5CC19C');

      const NewCasesPer100kData = {
        datasets: [
          {
            borderColor: 'rgba(92, 193, 156,0 )',
            backgroundColor: 'rgba(92, 193, 156, 0.3)',
            data: country_overall_cases_per_100k,
            pointBorderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 10,
            pointBackgroundColor: arrayOfColors1,
            label: 'label to remove2',
            fill: true,
            spanGaps: true
          },
          {
            borderColor: 'rgba(214, 88, 217, 0)',
            backgroundColor: 'rgba(214, 88, 217, 0.3)',
            data: city_overall_cases_per_100k,
            pointBorderWidth: 1,
            pointRadius: 0,
            pointHitRadius: 10,
            pointBackgroundColor: arrayOfColors2,
            fill: true,
            label: 'label to remove1',
            spanGaps: true
          },

          {
            borderColor: '#5CC19C',
            backgroundColor: '#5CC19C',
            data: country_14_day_data,
            pointBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            pointBackgroundColor: arrayOfColors4,
            label: `${country}`,
            fill: false,
            spanGaps: true
          },
          {
            borderColor: '#D658D9',
            backgroundColor: '#D658D9',
            data: city_14_day_data,
            pointBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
            pointBackgroundColor: arrayOfColors3,
            label: `${city}`,
            fill: false,
            spanGaps: true
          }
        ],
        labels: dateLabels
      };
      setGraphData(NewCasesPer100kData);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [cityState]);

  const options = {
    elements: {
      line: {
        tension: 0
      }
    },
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        fontColor: 'white',
        filter: function (item, chart) {
          if (item.text === 'label to remove1') {
            return;
          }
          if (item.text === 'label to remove2') {
            return;
          }
          return item;
        }
      }
    },
    steppedLine: true,
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem, data) {
          if (tooltipItem.datasetIndex === 0) {
            if (cardStyle === 'confirmedCases') {
              return `Single Day New Cases: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
            if (cardStyle === 'livesLost') {
              return `Single Day Lives Lost: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
          }
          if (tooltipItem.datasetIndex === 1) {
            if (cardStyle === 'confirmedCases') {
              return `Single Day New Cases: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
            if (cardStyle === 'livesLost') {
              return `Single Day Lives Lost: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
          }
          if (tooltipItem.datasetIndex === 2) {
            if (cardStyle === 'confirmedCases') {
              return `14 Day Average of New Cases: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
            if (cardStyle === 'livesLost') {
              return `14 Day Average of Lives Lost: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
          }
          if (tooltipItem.datasetIndex === 3) {
            if (cardStyle === 'confirmedCases') {
              return `14 Day Average of New Cases: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
            if (cardStyle === 'livesLost') {
              return `14 Day Average of Lives Lost: ${parseFloat(
                tooltipItem.value
              ).toFixed(3)}`;
            }
          }
        }
      }
    },
    plugins: {
      filler: {
        propagate: true
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString:
              cardStyle === 'confirmedCases'
                ? 'New Cases Per 100K'
                : 'Lives Lost Per 100K',
            fontColor: 'white'
          },
          ticks: {
            stepSize: 0.5,
            autoSkip: true,
            maxTicksLimit: 6,
            fontColor: 'white'
          }
        }
      ],
      xAxes: [
        {
          showFirstLabel: true,
          showLastLabel: true,
          ticks: {
            stepSize: 1,
            autoSkip: true,
            maxRotation: 30,
            minRotation: 30,
            fontColor: 'white'
          }
        }
      ]
    }
  };

  const Container = styled.div`
    height: ${panelState.Panel === false ? '100%' : '240px'};
    width: 100%;
    background-color: #31353d;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      height: ${panelState.Panel === false ? '100%' : '165px'};
    }
  `;
  const CardHeader = styled.div`
    height: 34.5px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: white;
    padding-left: 10px;
    box-sizing: border-box;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      height: unset;
      font-size: 14px;
    }

    position: relative;
  `;

  const Icon = styled.div`
    width: 26px;
    height: 26px;
    background-image: url(${props => props.image});
    margin-top: 5px;
    background-size: 100% 100%;
    margin-right: 10px;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      width: 18px;
      height: 18px;
    }
  `;

  const Title = styled.div`
    margin-top: 5px;
    font-size: 18px;
    color: white;
    @media screen and (max-height: ${windowHeightBreakpoint}) {
      font-size: 14px;
    }
  `;
  const GraphWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 80%;
  `;

  return (
    <Container>
      {loading ? (
        <LoadingReticule />
      ) : (
        <>
          <CardHeader>
            <Icon image={cardStyle === 'livesLost' ? flower : virus} />
            <Title> {cardStyle === 'livesLost' ? 'Lives Lost' : 'Cases'}</Title>
            <Toggle handleToggle={handleToggle} toggle={toggle} />
          </CardHeader>
          <div style={{ height: '10px' }} />
          <GraphWrapper>
            <Line
              data={graphData}
              height={null}
              width={null}
              options={options}
            />
          </GraphWrapper>
        </>
      )}
    </Container>
  );
};
export default FourteenDayAverageGraphs;
