import moment from 'moment-timezone';

const DetermineLastUpdate = () => {
  const easternTimeNow = moment.tz(moment(), 'America/Toronto');
  const easternTimeFourAMToday = moment.tz(
    moment({ hours: 4 }),
    'America/Toronto'
  );
  const easternTimeFourAMYesterday = moment.tz(
    moment({ hours: 4 }).subtract(1, 'days'),
    'America/Toronto'
  );
  const userTimezone = moment.tz.guess();
  let displayTime = easternTimeFourAMYesterday
    .clone()
    .tz(userTimezone)
    .format('YYYY-MM-DD LTS');
  if (easternTimeNow - easternTimeFourAMToday >= 0) {
    displayTime = easternTimeFourAMToday
      .clone()
      .tz(userTimezone)
      .format('YYYY-MM-DD LTS');
  }
  return displayTime;
};
export default DetermineLastUpdate;
