import React from 'react';
import styled from 'styled-components';

const ChartBars = styled.div`
  width: 80%;
  background-color: #22252c;
  height: 20%;
`;
const Bar = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-family: dax;
  width: 100%;
  position: relative;
  padding: 0.357em 0.714em 0.357em 0.714em;
`;
const BarInterior = styled.div`
  position: absolute;
  background-color: ${(props) => props.barColor};
  width: ${(props) => props.width}%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const HorizontalBar = ({ data, barColor }) => {
  const BarData1 = Math.round(data);

  return (
    <ChartBars>
      <Bar>
        <BarInterior width={BarData1} barColor={barColor} />
      </Bar>
    </ChartBars>
  );
};

export default HorizontalBar;
