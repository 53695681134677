import React from 'react';
import styled from 'styled-components';

import Panel from '../../../Panel';
import Indicators from '../../../DataPanel/PanelTypes/Indicators';
// import CityStats from '../../../DataPanel/PanelTypes/CityStats';
import ControllableIFrame from '../../../DataPanel/PanelTypes/ControllableIFrame';
import Impact from '../../../DataPanel/PanelTypes/Impact';
const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
  overflow: scroll;
`;

const CardSpacer = styled.div`
  margin-top: 10px;
`;

const Responsiveness = () => {
  return (
    <Container>
      <CardSpacer>
        <Panel>
          {/* <Indicators cardStyle="readiness" /> */}
          <Indicators cardStyle="responsiveness" />
        </Panel>
      </CardSpacer>

      <CardSpacer>
        <Panel>
          <Impact />
        </Panel>
      </CardSpacer>

      <CardSpacer>
        <Panel>
          <ControllableIFrame />
          {/* <CityStats /> */}
        </Panel>
      </CardSpacer>
    </Container>
  );
};

export default Responsiveness;
