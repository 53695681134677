import ReadinessColors from './ReadinessColors';
import ResponseColors from './ResponseColors';

const DetermineDoughnutColor = (mode) => {
  const DoughnutColorSchemes = {
    responsiveness: [
      ResponseColors[3],
      ResponseColors[2],
      ResponseColors[1],
      ResponseColors[0],
    ],
    readiness: [
      ReadinessColors[3],
      ReadinessColors[2],
      ReadinessColors[1],
      ReadinessColors[0]
    ]
  };

  return DoughnutColorSchemes[mode];
};

export default DetermineDoughnutColor;
