import React from 'react';
import ReactGA from 'react-ga';
import MapGL, {
  Source,
  Layer,
  FlyToInterpolator,
  NavigationControl
} from 'react-map-gl';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

import ResponseColors from '../../helpers/ResponseColors';
import CustomPopup from './TrendingPopup';
import CustomOldPopup from './ResponsePopup';
import { CityContext } from '../../context/CityProvider';
import { CityScoresContext } from '../../context/CityScoresProvider';
import { PhasedContentContext } from '../../context/PhasedContentProvider';
import { MapFiltersContext } from '../../context/MapFiltersProvider';
import './mapContainer.scss';

import TrendingColors from '../../helpers/TrendingColors';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const MapboxGl = ({ popupInfo, setPopupInfo }) => {
  const { cityScoresState } = React.useContext(CityScoresContext);
  const { phasedContentState } = React.useContext(PhasedContentContext);
  const { cityState, dispatchCityState } = React.useContext(CityContext);
  const { mapFiltersState } = React.useContext(MapFiltersContext);
  const mapRef = React.useRef(null);
  const [currentCity, setCurrentCity] = React.useState(null);
  const [segment, setSegments] = React.useState([]);
  const [hovering, setHovering] = React.useState(false);
  const [timerCountdown, setTimerCountdown] = React.useState(null);
  const [filterIDs, setFilterIds] = React.useState([]);

  const [viewport, setViewport] = React.useState({
    latitude: 37.7577,
    longitude: 20.4376,
    zoom: 1
  });

  React.useEffect(() => {
    if (cityState.FirstLoad) {
      setViewport({
        latitude: 37.7577,
        longitude: 20.4376,
        zoom: 1
      });
    } else {
      if (cityState.City.geometry) {
        const updateViewport = (longitude, latitude) => {
          let value = 2;
          viewport.zoom > 2 ? (value = viewport.zoom) : (value = 2);
          setViewport({
            longitude,
            latitude,
            zoom: value,
            transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
            transitionDuration: 'auto'
          });
        };
        updateViewport(
          cityState.City.geometry.coordinates[0],
          cityState.City.geometry.coordinates[1]
        );
        setPopupInfo(true);
        setCurrentCity(cityState.City);
      }
    }
    // eslint-disable-next-line
  }, [cityState.City, cityState.FirstLoad]);

  React.useEffect(() => {
    const segment1 = [];
    const segment2 = [];
    const segment3 = [];
    const segment4 = [];
    const segment5 = [];
    const segment6 = [];
    const segment7 = [];
    const segment8 = [];
    const segment9 = [];
    const segment10 = [];
    const segment11 = [];
    const segment12 = [];
    const segment13 = [];
    const segment14 = [];
    const segment15 = [];
    const segment16 = [];
    if (cityScoresState?.CityScores?.features) {
      phasedContentState.PhasedContent
        ? cityScoresState.CityScores.features.forEach((segment) => {
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= -100000
            ) {
              segment1.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= -10 &&
              segment.properties.new_cases_per100k_14d_change_percent >= -10000
            ) {
              segment2.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= -8 &&
              segment.properties.new_cases_per100k_14d_change_percent > -10
            ) {
              segment3.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= -6 &&
              segment.properties.new_cases_per100k_14d_change_percent > -8
            ) {
              segment4.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= -4 &&
              segment.properties.new_cases_per100k_14d_change_percent > -6
            ) {
              segment5.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <=
                -2.25 &&
              segment.properties.new_cases_per100k_14d_change_percent > -4
            ) {
              segment6.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= -1 &&
              segment.properties.new_cases_per100k_14d_change_percent > -2.25
            ) {
              segment7.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <=
                -0.25 &&
              segment.properties.new_cases_per100k_14d_change_percent > -1
            ) {
              segment8.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 0.25 &&
              segment.properties.new_cases_per100k_14d_change_percent > -0.25 &&
              segment.properties.new_cases_per100k_14d_change_percent !== null
            ) {
              segment9.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 1 &&
              segment.properties.new_cases_per100k_14d_change_percent > 0.25
            ) {
              segment10.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 2.25 &&
              segment.properties.new_cases_per100k_14d_change_percent > 1
            ) {
              segment11.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 4 &&
              segment.properties.new_cases_per100k_14d_change_percent > 2.25
            ) {
              segment12.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 6 &&
              segment.properties.new_cases_per100k_14d_change_percent > 4
            ) {
              segment13.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 8 &&
              segment.properties.new_cases_per100k_14d_change_percent > 6
            ) {
              segment14.push(segment);
            }
            if (
              segment.properties.new_cases_per100k_14d_change_percent <= 10 &&
              segment.properties.new_cases_per100k_14d_change_percent > 8
            ) {
              segment15.push(segment);
            }
            if (segment.properties.new_cases_per100k_14d_change_percent >= 10) {
              segment16.push(segment);
            }
          })
        : cityScoresState.CityScores.features.forEach((segment, index) => {
            if (
              segment.properties.overallResponsiveness > 0 &&
              segment.properties.overallResponsiveness <= 50
            ) {
              segment1.push(segment);
            }
            if (
              segment.properties.overallResponsiveness >= 51 &&
              segment.properties.overallResponsiveness <= 60
            ) {
              segment2.push(segment);
            }
            if (
              segment.properties.overallResponsiveness >= 61 &&
              segment.properties.overallResponsiveness <= 65
            ) {
              segment3.push(segment);
            }

            if (
              segment.properties.overallResponsiveness >= 66 &&
              segment.properties.overallResponsiveness <= 70
            ) {
              segment4.push(segment);
            }

            if (
              segment.properties.overallResponsiveness >= 71 &&
              segment.properties.overallResponsiveness <= 80
            ) {
              segment5.push(segment);
            }

            if (
              segment.properties.overallResponsiveness >= 81 &&
              segment.properties.overallResponsiveness <= 100
            ) {
              segment6.push(segment);
            }

            if (segment.properties.overallResponsiveness === '--') {
              segment7.push(segment);
            }
          });
      let arrSegs;
      phasedContentState.PhasedContent
        ? (arrSegs = [
            { type: 'FeatureCollection', features: segment1 },
            { type: 'FeatureCollection', features: segment2 },
            { type: 'FeatureCollection', features: segment3 },
            { type: 'FeatureCollection', features: segment4 },
            { type: 'FeatureCollection', features: segment5 },
            { type: 'FeatureCollection', features: segment6 },
            { type: 'FeatureCollection', features: segment7 },
            { type: 'FeatureCollection', features: segment8 },
            { type: 'FeatureCollection', features: segment9 },
            { type: 'FeatureCollection', features: segment10 },
            { type: 'FeatureCollection', features: segment11 },
            { type: 'FeatureCollection', features: segment12 },
            { type: 'FeatureCollection', features: segment13 },
            { type: 'FeatureCollection', features: segment14 },
            { type: 'FeatureCollection', features: segment15 },
            { type: 'FeatureCollection', features: segment16 }
          ])
        : (arrSegs = [
            { type: 'FeatureCollection', features: segment1 },
            { type: 'FeatureCollection', features: segment2 },
            { type: 'FeatureCollection', features: segment3 },
            { type: 'FeatureCollection', features: segment4 },
            { type: 'FeatureCollection', features: segment5 },
            { type: 'FeatureCollection', features: segment6 },
            { type: 'FeatureCollection', features: segment7 }
          ]);
      setSegments(arrSegs);

      const array = [];
      mapFiltersState.MapFilters.forEach((list) => {
        array.push(`index-${list}`);
      });

      setFilterIds(array);
    }
  }, [cityScoresState, phasedContentState, mapFiltersState]);

  const handleNewClick = (event) => {
    if (event.features.length > 0) {
      dispatchCityState({ type: 'firstLoad', data: false });
      dispatchCityState({ type: 'update', data: event.features[0] });
      ReactGA.event({
        category: 'Clicked on map',
        action: `${event.features[0].properties.city}`
      });
    }
  };

  const handleMouseOver = (event) => {
    if (event && event.features && event.features.length > 0) {
      setCurrentCity(event.features[0]);
      setPopupInfo(true);
    }
  };

  React.useEffect(() => {
    if (!hovering) {
      setTimerCountdown(setTimeout(() => setPopupInfo(false), 1500));
    }
    if (hovering) {
      setTimerCountdown(clearTimeout(timerCountdown));
    }
    /* eslint-disable-next-line */
  }, [hovering]);

  return (
    <MapGL
      {...viewport}
      ref={mapRef}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mapbox/dark-v10?optimize=true" // optimize=true
      onViewportChange={setViewport}
      interactiveLayerIds={filterIDs}
      onClick={(e) => handleNewClick(e)}
      onHover={(e) => handleMouseOver(e)}
      onMouseLeave={() => setHovering(false)}
      onMouseEnter={() => setHovering(true)}
      minZoom={1}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOXGL_KEY}
    >
      <NavigationControl showCompass={false} />

      {phasedContentState.PhasedContent && popupInfo && currentCity ? (
        <CustomPopup marker={currentCity} />
      ) : null}

      {phasedContentState.PhasedContent === false &&
      popupInfo &&
      currentCity ? (
        <CustomOldPopup marker={currentCity} />
      ) : null}

      {segment.map((data, index) => {
        return mapFiltersState.MapFilters.includes(String(index)) ? (
          <Source id={`index-${index}`} type="geojson" data={data}>
            <Layer
              id={`index-${index}`}
              type="circle"
              paint={{
                'circle-radius': 5,
                'circle-color': phasedContentState.PhasedContent
                  ? TrendingColors[index]
                  : ResponseColors[index],
                'circle-stroke-color': 'rgba(0, 0, 0, 0.3)',
                'circle-stroke-width': 1
              }}
            />
          </Source>
        ) : null;
      })}
    </MapGL>
  );
};
export default MapboxGl;
