const ResponseColors = [
  '#E22D4E',
  '#C34E00',
  '#FF8A00',
  '#FFC63C',
  '#D9D11B',
  '#5CC19C',
  '#989A9E',
  '#00AEEF',
  '#FB8A01'
];

export { ResponseColors as default };
