import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { MapFiltersContext } from '../../context/MapFiltersProvider';
import { PhasedContentContext } from '../../context/PhasedContentProvider';
import { windowWidthBreakpoint } from '../../helpers/StyleHelper';
// import ReadinessColors from '../../helpers/ReadinessColors';
import ResponseColors from '../../helpers/ResponseColors';
import TrendingColors from '../../helpers/TrendingColors';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const MapLegendContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 8px;
  height: auto;
  font-size: 12px;
  background-color: #1c1f26;
  padding: 0 10px;
  width: 115px;
`;

const MobileMapLegendContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 120px;
  padding-bottom: 5px;
  background-color: #1a1a1b;
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 85%;
`;

const LegendNodes = styled.div`
  width: 100%;
  flex-wrap: wrap;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  padding: 0 2px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #aaabae;
  transition: 0.3s ease all;
  &:hover {
    color: white;
  }
`;

const ColorNode = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 7px;
  border: 1px solid
    ${(props) => (props.color === '#000031' ? '#6D6D6D' : props.color)};
  background-color: ${(props) =>
    props.selected === true ? props.color : null};
  margin-right: 3px;
  flex-shrink: 0;
`;

const Description = styled.div`
  //text-transform: capitalize;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
`;

const TopHalf = styled.div`
  margin: 6px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #aaabae;
  @media screen and (max-width: ${windowWidthBreakpoint}) {
    font-size: 80%;
  }
`;
const BottomHalf = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  color: #aaabae;
  @media screen and (max-width: ${windowWidthBreakpoint}) {
    font-size: 80%;
  }
`;
const MobileBottomHalf = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: stretch;
  color: #aaabae;
  @media screen and (max-width: ${windowWidthBreakpoint}) {
    font-size: 80%;
  }
  overflow-y: scroll;
`;

const Degree = styled.div`
  color: white;
`;

const MobileToggle = styled.div`
  display: flex;
  border-radius: 20px;
  width: 80%;
  max-width: 415px;
  overflow: hidden;
  margin: 5px 0;
  text-align: center;
`;

const ReadinessButton = styled.div`
  width: 100%;
  padding: 12px 0px;
  background-color: ${(props) => (props.selected ? '#31353D' : '#66CC44')};
  color: ${(props) => (props.selected ? 'rgba(255, 255, 255, .5)' : 'white')};
`;

const ResponsivenessButton = styled.div`
  width: 100%;
  padding: 12px 0px;
  background-color: ${(props) =>
    props.selected ? '#31353D' : 'rgb(195, 78, 0)'};
  color: ${(props) => (props.selected ? 'rgba(255, 255, 255, .5)' : 'white')};
`;

const DesktopReadinessButton = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  padding: 2px 2px;
  width: 100%;
  background-color: ${(props) => (props.selected ? '#31353D' : '#66CC44')};
  color: ${(props) => (props.selected ? 'rgba(255, 255, 255, .5)' : 'white')};
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #66cc44;
    transition: background-color 0.5s ease;
  }
`;

const DesktopResponsivenessButton = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  padding: 2px 4px;
  width: 100%;
  background-color: ${(props) =>
    props.selected ? '#31353D' : 'rgb(195, 78, 0)'};
  color: ${(props) => (props.selected ? 'rgba(255, 255, 255, .5)' : 'white')};
  display: flex;
  justify-content: center;
  &:hover {
    background-color: rgb(195, 78, 0);
    transition: background-color 0.5s ease;
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const legendConfigTrending = {
  0: {
    color: TrendingColors[0],
    key: 'No score'
  },
  1: {
    color: TrendingColors[1],
    key: '-10'
  },
  2: {
    color: TrendingColors[2],
    key: '-10 to -8'
  },
  3: {
    color: TrendingColors[3],
    key: '-8 to -6'
  },
  4: {
    color: TrendingColors[4],
    key: '-6 to -4'
  },
  5: {
    color: TrendingColors[5],
    key: '-4 to -2.25'
  },
  6: {
    color: TrendingColors[6],
    key: '-2.25 to -1'
  },
  7: {
    color: TrendingColors[7],
    key: '-1 to -0.25'
  },
  8: {
    color: TrendingColors[8],
    key: 'Stable'
  },
  9: {
    color: TrendingColors[9],
    key: '0.25 to 1'
  },
  10: {
    color: TrendingColors[10],
    key: '1 to 2.25'
  },
  11: {
    color: TrendingColors[11],
    key: '2.25 to 4'
  },
  12: {
    color: TrendingColors[12],
    key: '4 to 6'
  },
  13: {
    color: TrendingColors[13],
    key: '6 to 8'
  },
  14: {
    color: TrendingColors[14],
    key: '8 to 10'
  },
  15: {
    color: TrendingColors[15],
    key: '10+ '
  }
};

const legendConfigResponsiveness = {
  0: {
    key: '0-50',
    color: ResponseColors[0]
  },
  1: {
    key: '51-60',
    color: ResponseColors[1]
  },
  2: {
    key: '61-65',
    color: ResponseColors[2]
  },
  3: {
    key: '66-70',
    color: ResponseColors[3]
  },
  4: {
    key: '71-80',
    color: ResponseColors[4]
  },
  5: {
    key: '81-100',
    color: ResponseColors[5]
  },
  6: {
    key: 'Unscored',
    color: ResponseColors[6]
  }
};

const MapLegend = ({ isMobileView }) => {
  const { mapFiltersState, dispatchMapFiltersState } = React.useContext(
    MapFiltersContext
  );
  const { phasedContentState, dispatchPhasedContentState } = useContext(
    PhasedContentContext
  );
  const [initFilter, setInitFilter] = React.useState(false);

  // reset filter state after toggling between readiness/responsiveness state
  useEffect(() => {
    dispatchMapFiltersState({
      type: 'update',
      data: phasedContentState.PhasedContent
        ? Object.keys(legendConfigTrending)
        : Object.keys(legendConfigResponsiveness)
    });
    setInitFilter(false);
  }, [dispatchMapFiltersState, phasedContentState.PhasedContent]);

  const GAEvent = (filter) => {
    ReactGA.event({
      category: 'Toggled Data',
      action: `${filter}`
    });
  };
  const filterChange = (value) => {
    if (!initFilter) {
      dispatchMapFiltersState({ type: 'update', data: [value] });
      setInitFilter(true);
      return;
    }
    let applicableNodes = mapFiltersState.MapFilters;
    if (applicableNodes.includes(value)) {
      applicableNodes = applicableNodes.filter((item) => item !== value);
    } else {
      applicableNodes.push(value);
    }
    dispatchMapFiltersState({ type: 'update', data: applicableNodes });
  };

  let buttons;

  const trendingButtons = () => {
    return Object.keys(legendConfigTrending).map((item) => {
      const { color, key } = legendConfigTrending[item];

      return (
        <LegendNodes
          onClick={() => filterChange(item)}
          isMobile={isMobileView}
          key={`nodes:${key}`}
        >
          <ColorNode
            selected={mapFiltersState.MapFilters.includes(item)}
            color={color}
          />

          <Description>{key}</Description>
        </LegendNodes>
      );
    });
  };

  const responsivenessButtons = () => {
    return Object.keys(legendConfigResponsiveness).map((item) => {
      const { color, key } = legendConfigResponsiveness[item];

      return (
        <LegendNodes
          onClick={() => filterChange(item)}
          isMobile={isMobileView}
          key={`nodes:${key}`}
        >
          <ColorNode
            selected={mapFiltersState.MapFilters.includes(item)}
            color={color}
          />

          <Description>{key}</Description>
        </LegendNodes>
      );
    });
  };

  phasedContentState.PhasedContent
    ? (buttons = trendingButtons())
    : (buttons = responsivenessButtons());

  return isMobileView ? (
    <MobileMapLegendContainer>
      <TopHalf>
        <Degree>Degree of</Degree>
        <MobileToggle>
          <ReadinessButton
            selected={phasedContentState.PhasedContent ? false : true}
            onClick={(event) => {
              dispatchPhasedContentState({
                type: 'update',
                data: !phasedContentState.PhasedContent
              });
            }}
          >
            Trending %
          </ReadinessButton>
          <ResponsivenessButton
            selected={phasedContentState.PhasedContent ? true : false}
            onClick={(event) => {
              dispatchPhasedContentState({
                type: 'update',
                data: !phasedContentState.PhasedContent
              });
            }}
          >
            Responsiveness
          </ResponsivenessButton>
        </MobileToggle>
      </TopHalf>
      <MobileBottomHalf>{buttons}</MobileBottomHalf>
    </MobileMapLegendContainer>
  ) : (
    <MapLegendContainer>
      <TopHalf>
        <Degree>Degree of: </Degree>
        <DesktopContainer>
          <DesktopReadinessButton
            selected={phasedContentState.PhasedContent ? false : true}
            onClick={(event) => {
              GAEvent('Readiness');
              dispatchPhasedContentState({
                type: 'update',
                data: !phasedContentState.PhasedContent
              });
            }}
          >
            Trending %
          </DesktopReadinessButton>
          <DesktopResponsivenessButton
            selected={phasedContentState.PhasedContent ? true : false}
            onClick={(event) => {
              GAEvent('Readiness');
              dispatchPhasedContentState({
                type: 'update',
                data: !phasedContentState.PhasedContent
              });
            }}
          >
            Responsiveness
          </DesktopResponsivenessButton>
        </DesktopContainer>
      </TopHalf>
      <BottomHalf>{buttons}</BottomHalf>
    </MapLegendContainer>
  );
};
export default MapLegend;
