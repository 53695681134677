import React from 'react';
import styled from 'styled-components';
import iframeLink from '../../../../data/IframeContent.json';

const Container = styled.iframe`
  width: 100%;
  height: 100%;
`;

const ControllableIFrame = () => {
  return <Container src={`${Object.values(iframeLink)[0]}`}></Container>;
};

export default ControllableIFrame;
