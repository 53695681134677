import React from 'react';

const initialMapFiltersState = {
  MapFilters: []
};
const MapFiltersReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        MapFilters: action.data
      };

    default:
      throw new Error('MapFilters missing');
  }
};

export const MapFiltersContext = React.createContext();
export const MapFiltersProvider = ({ children }) => {
  const [mapFiltersState, dispatchMapFiltersState] = React.useReducer(
    MapFiltersReducer,
    initialMapFiltersState
  );

  return (
    <MapFiltersContext.Provider
      value={{ mapFiltersState, dispatchMapFiltersState }}
    >
      {children}
    </MapFiltersContext.Provider>
  );
};
