import React, { useState } from 'react';
import styled from 'styled-components';
import Datatable from '../../../Datatable';
import AdvancedDataTable from '../../../AdvancedDatatable';
import ExpandImage from '../../../../assets/expand.svg';

const ExpandButton = styled.div`
  width: 56px;
  height: 56px;
  cursor: pointer;
  position: fixed;
  right: 12px;
  top: 106px;
  z-index: 100;
  background-image: url(${ExpandImage});
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
`;

const CityIndex = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {isExpanded ? (
        <AdvancedDataTable />
      ) : (
        <Datatable
          isMobileView={true}
          setIsLoaded={(value) => setIsLoaded(value)}
        />
      )}
      {isLoaded && <ExpandButton onClick={() => setIsExpanded(!isExpanded)} />}
    </>
  );
};

export default CityIndex;
