import React from 'react';

const initialCityScoresState = {
  CityScores: {},
  HistoricalData: {}
};
const CityScoresReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        CityScores: action.data
      };

    case 'updateHistory':
      return {
        ...state,
        HistoricalData: action.data
      };

    default:
      throw new Error('CityScores missing');
  }
};

export const CityScoresContext = React.createContext();
export const CityScoresProvider = ({ children }) => {
  const [cityScoresState, dispatchCityScoresState] = React.useReducer(
    CityScoresReducer,
    initialCityScoresState
  );

  return (
    <CityScoresContext.Provider
      value={{ cityScoresState, dispatchCityScoresState }}
    >
      {children}
    </CityScoresContext.Provider>
  );
};
