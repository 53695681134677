const ReadinessColors = [
  '#0072B3',
  '#00AEEF',
  '#3CDCFF',
  '#BBF7FF',
  '#E3FFFF',
  '#5CC19C',
  '#989A9E',
  '#00AEEF',
  '#FB8A01'
];

export { ReadinessColors as default };
