import React from 'react';
const tabs = ['map', 'index', 'readiness', 'impact']
const initialMobileState = {
  Tab: 'map'
};
const MobileReducer = (state, action) => {
  switch (action.type) {
    case 'switchTab':
      if(tabs.includes(action.data)){
        return {
          ...state,
          Tab: action.data
        };
      } else {
        throw new Error('Tab undefined');
      }
    default:
      throw new Error('Modal reducer case not found');
  }
};

export const MobileContext = React.createContext();
export const MobileProvider = ({ children }) => {
  const [MobileState, dispatchMobileState] = React.useReducer(
    MobileReducer,
    initialMobileState
  );

  return (
    <MobileContext.Provider
      value={{ MobileState, dispatchMobileState }}
    >
      {children}
    </MobileContext.Provider>
  );
};
