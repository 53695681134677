const TrendingColors = [
  'grey',
  '#66CC44',
  '#86BE74',
  '#8CBC7D',
  '#93B987',
  '#99B691',
  '#A0B49A',
  '#A6B1A4',
  '#ADAFAE',
  '#B49F9E',
  '#BB8F8E',
  '#C37F7E',
  '#CA6F6E',
  '#D25F5E',
  '#D94F4F',
  '#FF0000'
];

export { TrendingColors as default };
