import React from 'react';

const initialCityState = {
  City: [],
  FirstLoad: true
};
const CityReducer = (state, action) => {
  switch (action.type) {
    case 'firstLoad':
      return {
        ...state,
        FirstLoad: action.data
      };
    case 'update':
      return {
        ...state,
        City: action.data
      };

    default:
      throw new Error('Modal reducer case not found');
  }
};

export const CityContext = React.createContext();
export const CityProvider = ({ children }) => {
  const [cityState, dispatchCityState] = React.useReducer(
    CityReducer,
    initialCityState
  );

  return (
    <CityContext.Provider value={{ cityState, dispatchCityState }}>
      {children}
    </CityContext.Provider>
  );
};
