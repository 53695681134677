import React, { useRef } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import MUIDataTable from 'mui-datatables';
import { isMobile } from 'react-device-detect';
import DoughNutChart from '../DoughnutChart';

import DetermineDoughnutColor from '../../helpers/DetermineDoughnutColor';
import { CityContext } from '../../context/CityProvider';
import { MobileContext } from '../../context/MobileProvider';
import { CityScoresContext } from '../../context/CityScoresProvider';
import { PhasedContentContext } from '../../context/PhasedContentProvider';
import { StyledScrollbar } from '../../helpers/StyleHelper';
import DetermineTrendingColor from '../../helpers/DetermineTrendingColor';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const Datatable = ({ isMobileView, setIsLoaded }) => {
  const { cityScoresState } = React.useContext(CityScoresContext);
  const { cityState, dispatchCityState } = React.useContext(CityContext);
  const { phasedContentState } = React.useContext(PhasedContentContext);
  const { FirstLoad } = cityState;
  const { dispatchMobileState } = React.useContext(MobileContext);
  const [data, setData] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [filter, setFilter] = React.useState([]);
  const [activeColumn, setActiveColumn] = React.useState({
    column: 2,
    sort: 'desc'
  });
  const tableRef = useRef(null);

  const handleRowClick = (index) => {
    dispatchCityState({ type: 'firstLoad', data: false });
    dispatchCityState({ type: 'update', data: index[0] });
    ReactGA.event({
      category: 'City Selected',
      action: `${index[0].properties.city}`
    });

    if (isMobile) {
      dispatchMobileState({ type: 'switchTab', data: 'readiness' });
    }
  };
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiPaper: {
          root: {
            backgroundColor: '#2C2F36',
            color: 'white',
            width: '100%',
            height: '100% !important',
            overflow: 'hidden'
          },
          elevation2: { width: '200px', height: '200px' },
          elevation8: { width: '300px', height: '300px' }
        },
        MuiTable: {
          root: {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            bottom: '0'
          }
        },
        MUIDataTableBodyRow: {
          root: {
            cursor: 'pointer',
            width: '100%',
            '&:nth-child(odd)': {
              backgroundColor: '#2C2F36'
            },
            '&:nth-child(even)': {
              backgroundColor: '#31353D'
            }
          }
        },

        MuiFormLabel: {
          filled: {
            color: 'white'
          }
        },
        MUIDataTableFilter: {
          root: {
            backgroundColor: '#2C2F36',

            height: '100%'
          },
          title: {
            color: 'white'
          }
        },

        MUIDataTableToolbar: {
          root: {
            display: 'flex !important',
            width: isMobileView ? '100%' : '23.5vw',
            paddingRight: isMobileView ? '50px' : '16px'
          },
          titleRoot: {
            display: 'flex'
          },
          actions: {
            paddingRight: '10px',
            textAlign: 'right !important'
          },
          left: {
            // marginLeft: '40px !important'
          },
          icon: {
            color: 'white'
          },
          iconActive: {
            color: 'white'
          },
          filterPaper: {
            height: 'auto !important'
          }
        },

        MUIDataTableHeadCell: {
          root: {
            color: 'white',
            paddingLeft: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '13px'
            // whiteSpace: 'nowrap',
            // '&:first-child': {
            //   paddingLeft: '10px'
            // },
            // '&:last-child': {
            //   paddingRight: '10px'
            // }
          },
          sortActive: { color: 'white' },
          sortAction: { color: 'white', alignItems: 'center' }
        },
        MuiTableCell: {
          head: {
            backgroundColor: '#2C2F36 !important',
            color: 'white'
          },

          root: {
            padding: '0px',
            borderBottom: '0'
          }
        },

        MUIDataTableBodyCell: {
          root: {
            padding: '0px',
            paddingRight: '3px',
            paddingLeft: '3px',
            color: 'white !important'
          }
        },
        MuiTableFooter: {
          root: {
            width: '100%'
          }
        },
        MUIDataTablePagination: {
          root: {
            padding: '0 !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }
        },

        MUIDataTable: {
          paper: {
            height: '100% !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'stretch'
          },
          responsiveScroll: {
            // maxHeight: '76vh !important',
            height: '100%',
            width: isMobileView ? '100%' : '23.5vw',
            ...StyledScrollbar,
            maxHeight: 'unset !important',
            flex: 1,
            flexGrow: 1
          }
        },
        MuiInput: {
          formControl: {
            color: 'white'
          },
          underline: {
            '&:hover:before': {
              borderBottom: '0px solid white !important'
            },
            '&:before': {
              borderBottom: '0px solid white !important'
            },
            '&:after': {
              borderBottom: '0px solid white'
            }
          }
        },
        MuiInputBase: {
          input: {
            background: 'lightgrey',
            color: '#31353d',
            padding: '6px 10px 7px'
          }
        },
        MUIDataTableViewCol: {
          title: {
            color: 'white'
          },
          label: {
            color: 'white'
          }
        },
        MUIDataTableSearch: {
          main: {
            flex: '1 0 auto',
            display: 'flex',
            alignItems: 'center',
            background: 'inherit',
            marginTop: '0',
            zIndex: '1',
            position: 'relative'
          },
          searchIcon: {
            color: 'white',
            marginRight: '8px !important',
            display: 'none'
          },
          clearIcon: { color: 'white' }
        },
        MuiSvgIcon: { root: { margin: '0 !important' } },
        MuiTableSortLabel: {
          active: {
            color: 'white !important'
          },
          icon: { root: { margin: '0' } },
          iconDirectionDesc: { color: 'white !important' },
          iconDirectionAsc: { color: 'white !important' }
        }
      }
    });

  React.useEffect(() => {
    const row = [];

    if (
      cityScoresState &&
      cityScoresState.CityScores &&
      cityScoresState.CityScores.features &&
      Object.keys(cityScoresState.CityScores.features).length > 0
    ) {
      // this logic was in place to have the city with the highest overallReadiness score as the default city, this was changed to LA on june 4th, 2020
      // let highestScore = 0;
      // let finalIndex = [];
      let LosAngeles = [];

      cityScoresState.CityScores.features.forEach((individualCity, index) => {
        const {
          city,
          country,
          overallResponsiveness,
          new_cases_per100k_14d_change_percent
        } = individualCity.properties;

        const wholeStore = individualCity;
        if (individualCity.properties.city === 'Los Angeles') {
          LosAngeles = individualCity;
        }

        row.push([
          wholeStore,
          wholeStore,
          new_cases_per100k_14d_change_percent,
          overallResponsiveness,
          city,
          country
        ]);
      });

      setData(row);

      if (FirstLoad) {
        // dispatchCityState({ type: 'update', data: finalIndex });
        dispatchCityState({ type: 'update', data: LosAngeles });
      }
    }
    // eslint-disable-next-line
  }, [
    cityScoresState,
    dispatchCityState,
    phasedContentState,
    isMobile,
    FirstLoad
  ]);

  const columns = [
    {
      name: 'Data',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, x) => {
          return (
            <div
              style={{
                height: '54px',
                width: '5px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  height: '54px',
                  width: '5px',
                  backgroundColor: DetermineTrendingColor(
                    value.properties.new_cases_per100k_14d_change_percent
                  )
                }}
              ></div>
            </div>
          );
        }
      }
    },

    {
      name: 'Trending Percent',
      options: {
        sortDirection: activeColumn.column === 2 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        customBodyRender: (value, tableCell) => {
          return (
            <div
              style={{
                height: '54px',
                width: '54px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  height: '48px',
                  width: '48px',
                  marginLeft: '5px'
                }}
              >
                <DoughNutChart
                  dataWeight={'trending'}
                  score={value}
                  id={tableCell}
                  overlaySize={value < -100000 ? '20px' : '12px'}
                  overlayColor="white"
                />
              </div>
            </div>
          );
        }
      }
    },

    {
      name: 'Response Score',
      options: {
        sortDirection: activeColumn.column === 3 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        customBodyRender: (value, x) => {
          return (
            <div
              style={{
                height: '54px',
                width: '50px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  height: '48px',
                  width: '48px',
                  marginLeft: '5px'
                }}
              >
                <DoughNutChart
                  dataWeight={'responsiveness'}
                  score={value}
                  id={x}
                  overlaySize="20px"
                  overlayColor="white"
                  doughNutColors={DetermineDoughnutColor('responsiveness')}
                />
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'City',
      options: {
        sortDirection: activeColumn.column === 4 ? activeColumn.sort : null,
        filter: false,
        sort: true,
        display: true
      }
    },
    {
      name: 'Country',
      options: {
        sortDirection: activeColumn.column === 5 ? activeColumn.sort : null,
        filterList: filter,
        filter: false,
        sort: true,
        display: true
      }
    }
  ];
  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'scroll',
    rowsPerPageOptions: [],
    rowsPerPage: 50,
    print: false,
    download: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    viewColumns: false,
    sort: true,
    page: 0,
    // searchPlaceholder: 'Search',
    searchText,
    onColumnSortChange: () => {
      tableRef.current.changePage(0);
    },
    onFilterChange: (columnChanged, filterList) => {
      setFilter(filterList[4]);
    },
    onSearchChange: (searchText) => setSearchText(searchText),
    onRowClick: (index, i) => handleRowClick(index, i),
    onChangePage: () => {
      const table = document.querySelector('table');
      table.scrollIntoView(true);
    },
    onTableChange: (action, tableState) => {
      if (action === 'sort') {
        if (
          tableState &&
          tableState.announceText &&
          tableState.announceText.includes('descending')
        ) {
          setActiveColumn({ column: tableState.activeColumn, sort: 'desc' });
        }
        if (
          tableState &&
          tableState.announceText &&
          tableState.announceText.includes('ascending')
        ) {
          setActiveColumn({ column: tableState.activeColumn, sort: 'asc' });
        }
      }
    }
  };

  return (
    <Container>
      <React.Fragment>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            innerRef={tableRef}
            title="City Index"
            data={data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </React.Fragment>
    </Container>
  );
};

export default Datatable;
