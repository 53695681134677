import React from 'react';

const initialPhasedContentState = {
  PhasedContent: true
};
const PhasedContentReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        PhasedContent: action.data
      };

    default:
      throw new Error('Modal reducer case not found');
  }
};

export const PhasedContentContext = React.createContext();
export const PhasedContentProvider = ({ children }) => {
  const [phasedContentState, dispatchPhasedContentState] = React.useReducer(
    PhasedContentReducer,
    initialPhasedContentState
  );

  return (
    <PhasedContentContext.Provider
      value={{ phasedContentState, dispatchPhasedContentState }}
    >
      {children}
    </PhasedContentContext.Provider>
  );
};
