import React from 'react';
import styled from 'styled-components';

const GraphToggle = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 40%;
`;

const Toggle14Day = styled.div`
  font-size: 12px;
  border-top: ${(props) =>
    props.selected ? '2px solid white' : '2px solid transparent'};
  border-left: ${(props) =>
    props.selected ? '2px solid white' : '2px solid transparent'};
  border-right: ${(props) =>
    props.selected ? '2px solid white' : '2px solid transparent'};
  background-color: ${(props) => (props.selected ? 'transparent' : '#262626')};
  padding: 3px;
  cursor: pointer;
`;
const ToggleAllTime = styled.div`
  font-size: 12px;
  border-top: ${(props) =>
    props.selected ? '2px solid white' : '2px solid transparent'};
  border-left: ${(props) =>
    props.selected ? '2px solid white' : '2px solid transparent'};
  border-right: ${(props) =>
    props.selected ? '2px solid white' : '2px solid transparent'};
  background-color: ${(props) => (props.selected ? 'transparent' : '#262626')};
  padding: 3px;
  cursor: pointer;
`;

const Toggle = ({ handleToggle, toggle }) => {
  return (
    <GraphToggle>
      <Toggle14Day
        selected={toggle === '14Day'}
        onClick={() => handleToggle('14Day')}
      >{`14 Day Average`}</Toggle14Day>

      <ToggleAllTime
        selected={toggle === 'AllTime'}
        onClick={() => handleToggle('AllTime')}
      >
        {'Cumulative'}
      </ToggleAllTime>
    </GraphToggle>
  );
};

export default Toggle;
