import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import { CityScoresContext } from '../../context/CityScoresProvider';
import parseData from '../../helpers/parseData';
import CityElevenHundred from '../../data/CityScores';

import { isMobile } from 'react-device-detect';
import DesktopContainer from '../DesktopContainer';
import MobileContainer from '../MobileContainer';

const axios = require('axios');
const dataUrl = process.env.REACT_APP_DATA_URL || '';
// the historical data must be in the public folder

const App = () => {
  const { dispatchCityScoresState } = React.useContext(CityScoresContext);
  const fetchPublicData = async () => {
    const getCityData = async () => {
      const url = `${dataUrl}/HistoricalCityImpact.json`;
      return await axios.get(url, {});
    };

    const getCountryData = async () => {
      const url = `${dataUrl}/HistoricalCountryImpact.json`;
      return await axios.get(url, {});
    };
    return await Promise.all([getCityData(), getCountryData()]);
  };

  React.useEffect(() => {
    const getSiteData = async () => {
      dispatchCityScoresState({
        type: 'update',
        data: parseData(CityElevenHundred)
      });

      const [
        HistoricalCityImpact,
        HistoricalCountryImpact
      ] = await fetchPublicData();

      dispatchCityScoresState({
        type: 'updateHistory',
        data: {
          cityData: HistoricalCityImpact.data,
          countryData: HistoricalCountryImpact.data
        }
      });
    };

    getSiteData();
    // eslint-disable-next-line
  }, []);

  return isMobile ? <MobileContainer /> : <DesktopContainer />;
};

export default App;
