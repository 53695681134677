import React from 'react';
import { render } from 'react-dom';
import App from './components/App/App';
import { CityProvider } from './context/CityProvider';
import { PanelProvider } from './context/PanelProvider';
import { PhasedContentProvider } from './context/PhasedContentProvider';
import { CityScoresProvider } from './context/CityScoresProvider';
import { MobileProvider } from './context/MobileProvider';
import { MapFiltersProvider } from './context/MapFiltersProvider';

render(
  <CityProvider>
    <PanelProvider>
      <PhasedContentProvider>
        <CityScoresProvider>
          <MobileProvider>
            <MapFiltersProvider>
              <App />
            </MapFiltersProvider>
          </MobileProvider>
        </CityScoresProvider>
      </PhasedContentProvider>
    </PanelProvider>
  </CityProvider>,
  document.getElementById('root')
);
