const parseCity = (data) => {
  const value = data.map((index) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [Number(index.longitude), Number(index.latitude)]
    },
    properties: {
      cityId: index.city_id,
      city: index.city,
      country: index.country,
      population: index.population,
      overallReadiness: index.OverallReadiness
        ? Number(index.OverallReadiness).toFixed(0)
        : '--',
      publicHealthCapacity: Number(
        Number(index.PublicHealthCapacity).toFixed(0)
      ),
      societalStrength: Number(Number(index.SocietalStrength).toFixed(0)),
      economicAbility: Number(Number(index.EconomicAbility).toFixed(0)),
      infrastructure: Number(Number(index.Infrastructure).toFixed(0)),
      nationalCollaborativeWill: Number(
        Number(index.NationalCollaborativeWill).toFixed(0)
      ),
      overallResponsiveness: index.OverallResponsiveness
        ? Number(index.OverallResponsiveness).toFixed(0)
        : '--',
      healthcareResponse: index.HealthCareResponse
        ? Number(index.HealthCareResponse).toFixed(0)
        : '--',
      economicResponse: index.EconomicResponse
        ? Number(index.EconomicResponse).toFixed(0)
        : '--',
      socialResponse: index.SocialResponse
        ? Number(index.SocialResponse).toFixed(0)
        : '--',
      supplyManagementResponse: index.SupplyManagementResponse
        ? Number(index.SupplyManagementResponse).toFixed(0)
        : '--',
      new_cases_per100k:
        index.new_cases_per100k || index.new_cases_per100k === 0
          ? Number(index.new_cases_per100k)
          : -1000000000,
      new_cases_per100k_14d_avg:
        index.new_cases_per100k_14d_avg || index.new_cases_per100k_14d_avg === 0
          ? Number(index.new_cases_per100k_14d_avg)
          : -1000000000,
      new_cases_per100k_14d_change_percent:
        index.new_cases_per100k_14d_change_percent ||
        index.new_cases_per100k_14d_change_percent === 0
          ? Number(index.new_cases_per100k_14d_change_percent)
          : -1000000000,
      new_lives_lost_per100k_14d_avg:
        index.new_lives_lost_per100k_14d_avg ||
        index.new_lives_lost_per100k_14d_avg === 0
          ? Number(index.new_lives_lost_per100k_14d_avg)
          : -1000000000,
      new_lives_lost_per100k_14d_change_percent:
        index.new_lives_lost_per100k_14d_change_percent ||
        index.new_lives_lost_per100k_14d_change_percent === 0
          ? Number(index.new_lives_lost_per100k_14d_change_percent)
          : -1000000000
    }
  }));
  return {
    type: 'FeatureCollection',
    features: value
  };
};
export default parseCity;
