import React from 'react';
import styled from 'styled-components';

import Panel from '../../../Panel';
import Impact from '../../../DataPanel/PanelTypes/Impact';
import CumulativeGraphs from '../../../DataPanel/PanelTypes/CumulativeGraphs';
import FourteenDayAverageGraphs from '../../../DataPanel/PanelTypes/FourteenDayAverageGraphs';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
`;

const CardSpacer = styled.div`
  margin-top: 10px;
`;

const Readiness = () => {
  const [casesToggle, setCasesToggle] = React.useState('14Day');
  const handleCasesToggle = (value) => {
    setCasesToggle(value);
  };
  return (
    <Container>
      <CardSpacer>
        <Panel>
          <CumulativeGraphs cardStyle="livesLost" />
        </Panel>
      </CardSpacer>
      <CardSpacer>
        <Panel>
          {casesToggle === '14Day' ? (
            <FourteenDayAverageGraphs
              cardStyle="confirmedCases"
              handleToggle={handleCasesToggle}
              casesToggle={casesToggle}
            />
          ) : null}
          {casesToggle === 'AllTime' ? (
            <CumulativeGraphs
              cardStyle="confirmedCases"
              handleToggle={handleCasesToggle}
              casesToggle={casesToggle}
            />
          ) : null}
        </Panel>
      </CardSpacer>
      <CardSpacer>
        <Panel>
          <Impact />
        </Panel>
      </CardSpacer>
    </Container>
  );
};

export default Readiness;
