import React from 'react';
import VerticalPanel from './VerticalPanel';
import HorizontalPanel from './HorizontalPanel';
import './card.scss';

const DataPanel = () => {
  return (
    <>
      <VerticalPanel />
      <HorizontalPanel />
    </>
  );
};

export default DataPanel;
