import React from 'react';
import styled from 'styled-components';
import { Popup } from 'react-map-gl';
import { isMobile } from 'react-device-detect';
import DetermineTrendingColor from '../../helpers/DetermineTrendingColor';
import { CityContext } from '../../context/CityProvider';
import { MobileContext } from '../../context/MobileProvider';

const PopupContainer = styled.div`
  width: auto;
  min-width: 125px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1c1f26;
  border-radius: 5px;
  box-sizing: border-box;
  padding-bottom: 8px;
`;

const ColorTheme = styled.div`
  height: 10px;
  width: 100%;
  background-color: ${(props) => props.color};
  margin-bottom: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const City = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Heebo, sans-serif;
  text-align: center;
  height: auto;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
`;
const Country = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Heebo, sans-serif;
  height: auto;
  color: white;
`;

const CustomPopup = ({ marker }) => {
  const { dispatchCityState } = React.useContext(CityContext);
  const { dispatchMobileState } = React.useContext(MobileContext);
  return (
    <Popup
      key={`popup-${marker}`}
      tipSize={5}
      anchor="bottom"
      longitude={marker.geometry.coordinates[0]}
      latitude={marker.geometry.coordinates[1]}
      offsetTop={-15}
      offsetLeft={0}
      closeOnClick
      closeButton={false}
    >
      <PopupContainer>
        <ColorTheme
          color={DetermineTrendingColor(
            marker.properties.new_cases_per100k_14d_change_percent
          )}
        />
        <City
          onClick={(event) => {
            event.stopPropagation();
            dispatchCityState({ type: 'firstLoad', data: false });
            dispatchCityState({ type: 'update', data: marker });
            if (isMobile) {
              dispatchMobileState({ type: 'switchTab', data: 'readiness' });
            }
          }}
        >
          {marker.properties.city}
        </City>
        <Country
          onClick={(event) => {
            event.stopPropagation();
            dispatchCityState({ type: 'firstLoad', data: false });
            dispatchCityState({ type: 'update', data: marker });
            if (isMobile) {
              dispatchMobileState({ type: 'switchTab', data: 'readiness' });
            }
          }}
        >
          {marker.properties.country}
        </Country>
      </PopupContainer>
    </Popup>
  );
};

export default CustomPopup;
